import { Avatar, CircularProgress } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { dataToken } from "../../Config/token";
import { check_user } from "../../utils";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { MenuItem, TextField } from "@mui/material";

const type = [
  {
    value: "user_id",
    label: "User ID",
  },
  {
    value: "mobile_number",
    label: "Mobile Number",
  },
];

const UVModal = ({ show4, setShow4 }) => {
  const [loading, setLoading] = useState(false);
  const [dataupload, setdataUpload] = useState(undefined);
  const [id, setId] = React.useState("user_id");


  const imageUpload1 = (e) => {
    setLoading(true);
    const axiosData = new FormData();
    axiosData.append("file", e.target.files[0]);
    axiosData.append("type", id);
    axios
      .post(check_user, axiosData, dataToken)
      .then((res) => {
        
        setdataUpload(res.data.users);

        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  return (
    <div>
      <Modal
        style={{ marginTop: "100px" }}
        show={show4}
        onHide={() => setShow4(false)}
      >
        <div className="total_fse_search d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex  align-items-center">
            <h4 className="mr-5 leadgenerator">User Validator</h4>

            <TextField
              id="outlined-select-id"
              variant="outlined"
              select
              size="small"
              defaultValue={id}
              onChange={(e) => setId(e.target.value)}
            >
              {type.map((el) => (
                <MenuItem key={el.value} value={el.value}>
                  {el.label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div>
            {!dataupload && (
              <label>
                {loading ? (
                  <CircularProgress size={24} className="text-light" />
                ) : (
                  <Avatar
                    sx={{
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                    style={{ background: "#663595", cursor: "pointer" }}
                  >
                    <FileUploadIcon />
                  </Avatar>
                )}
                <input
                  type="file"
                  onChange={(e) => {
                    imageUpload1(e);
                  }}
                />
              </label>
            )}
            {dataupload && (
              <CSVLink
                data={dataupload}
                filename={"my-file.csv"}
                className="down_load_btn  mt-2"
                target="_blank"
              >
                <Avatar
                  sx={{ bgcolor: "black", fontSize: "12px", cursor: "pointer" }}
                  style={{ background: "#663595", cursor: "pointer" }}
                >
                  <DownloadIcon />
                </Avatar>
              </CSVLink>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UVModal;
