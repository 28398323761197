import {
  Avatar,
  CardContent,
  FormHelperText,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useContext } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { login_number, otp_number } from "../../utils";
import axios from "axios";
import { FinanceContext } from "../../App";
import Grid from "@mui/material/Grid";
import { Button } from "@material-ui/core";
import ReactCardFlip from "react-card-flip";
import OtpInput from "react-otp-input";
const CardStyle = {
  border: "0.1px solid #fff",
  borderRadius: "10px",
  padding: "20px",
  margin: "20px",
  width: "330px",
  height: "250px",
  boxShadow: "rgba(0, 0, 0, 0.2) 0px 18px 50px -10px",
};

function Number() {
  const [isFlipped, setIsFlipped] = useState(false);
  const navigate = useNavigate();
  const [context, setContext] = useContext({ ...FinanceContext });

  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(true);
  const [err, setErr] = useState(false);
  const [erro, setErro] = useState(false);

  const handleOtpInput = (e) => {
    setOtp(e);
  };

  const handleOTP = () => {
    setErro(false);
    const dataInput = {
      mobile_number: context.otp_mobile,
      otp: otp,
    };

    axios
      .post(otp_number, dataInput)
      .then((res) => {
        if (res.data.error) {
          setErro(true);
          // setShake(true);
          setTimeout(() => {
            // setShake(false);
          }, 1000);
        } else {
          localStorage.setItem("finance_email", res.data.token);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("type", res?.data?.users?.type);
          localStorage.setItem("finance_id", res.data.users.id);
          localStorage.setItem("full_name", res.data.users.full_name[0]);
          navigate("/invoice2022");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleResend = () => {
    const dataNumber = {
      mobile_number: `${context.otp_mobile}`,
    };
    axios
      .post(login_number, dataNumber)
      .then((res) => {
        console.log(res, "res");
        setErro(false);
        // setError(true);
        // setCounter(59);

        // Swal.fire({
        //   position: "center",
        //   icon: "success",
        //   title: "OTP has been send to the registered Number",
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const onlyNums = isNaN(e.target.value);
    if (!onlyNums) {
      setValue(e.target.value);
    } else {
      const num = e.target.value;
      const res = num.slice(0, num.length - 1);
      setValue(res);
    }
  };

  const handleNumber = () => {
    setLoading(true);
    setErr(false);
    const dataNumber = {
      mobile_number: `${value}`,
    };
    context.otp_mobile = value;
    setContext({ ...context });
    axios
      .post(login_number, dataNumber)
      .then((res) => {
        setLoading(false);
        setIsFlipped((prev) => !prev);
        // navigate("/login/otp");
      })
      .catch((err) => {
        setLoading(false);
        setErr(true);
        console.log(err);
      });
  };

  return (
    // <div className="p_login">
    //   <div className="log_col1">
    //     <img
    //       src={window.location.origin + "/images/logopurple.svg"}
    //       alt="login"
    //       className="logo_css_purp"
    //     />
    //     <img
    //       src={window.location.origin + "/images/logo.svg"}
    //       alt="login"
    //       className="login_bg"
    //       data-aos="fade-right"
    //       data-aos-duration="600"
    //     />
    //   </div>
    //   <div className="log_col22" style={{ alignItems: "center" }}>
    //     <h1 className="log_name">Finance Dashboard</h1>
    //     {/* <h2 className="log_sub"> Dashboard</h2> */}
    //     <Grid container direction={"column"} spacing={4} alignItems="center">
    //       <Grid item>
    //         <TextField
    //           sx={{ width: "285px" }}
    //           onChange={handleChange}
    //           // id="outlined-password-input"
    //           // label="Mobile Number"
    //           placeholder=" Mobile Number"
    //           type="Mobile Number"
    //           value={value}
    //           autoComplete="current-password"
    //           inputProps={{ maxLength: 10 }}
    //         />
    //       </Grid>
    //       <Grid item>
    //         {/* <button
    //           className={
    //             value?.length > 9 && !loading ? "btn_login" : "btn_login_off"
    //           }
    //           onClick={handleNumber}
    //           style={{width:'285px'}}
    //         >
    //           Login
    //         </button> */}
    //         <Button
    //           style={{
    //             width: "285px",
    //             background:
    //               value?.length > 9 && !loading ? "#663595" : "#dfdfdf",
    //             color: value?.length > 9 && !loading ? "#fff" : "#000",
    //             borderRadius: "5px",
    //           }}
    //           onClick={handleNumber}
    //           fullWidth
    //           variant="contained"
    //         >
    //           Login
    //         </Button>
    //         {/* <Button sx={{width:'285px'}} fullWidth className={
    //             value?.length > 9 && !loading ? "btn_login" : "btn_login_off"
    //           } onClick={handleNumber}>Login</Button> */}
    //         {/* <Button variant="contained" fullWidth sx={{width:'285px'}} onClick={handleNumber}> Login</Button> */}
    //       </Grid>
    //     </Grid>
    //   </div>
    // </div>

    <div>
      <Grid
        container
        style={{ width: "100%", height: "100vh", background: "#663595" }}
      >
        <Grid item width={"40%"}>
          <Grid
            container
            style={{ height: "100vh" }}
            justifyContent={"center"}
            alignItems="center"
          >
            <Grid item>
              <img src="/images/taskmoLoginLogo.png" alt="" />
            </Grid>
            <Grid item>
              <img src="/images/HomeImage.png" alt="" height="500px" />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          width={"60%"}
          style={{
            height: "100%",
            borderRadius: "50px 0px 0px 50px",
            background: "#fff",
          }}
        >
          <Grid
            container
            justifyContent={"center"}
            flexDirection="column"
            style={{ height: "100vh" }}
          >
            <Grid item>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  variant="h4"
                  style={{
                    color: "#663595",
                    fontFamily: "Dosis",
                    fontWeight: "900",
                  }}
                >
                  FINANCE DASHBOARD
                </Typography>
              </div>
            </Grid>
            <Grid item>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // height: "100vh",
                }}
              >
                <ReactCardFlip
                  className="characterCard"
                  isFlipped={isFlipped}
                  flipDirection="horizontal"
                  flipSpeedFrontToBack="1"
                  flipSpeedBackToFront="1"
                >
                  <div style={CardStyle}>
                    <Grid container spacing={3} style={{ marginTop: "10px" }}>
                      <Grid item>
                        <Grid container flexDirection={"column"} gap="10px">
                          <label>
                            <Typography
                              variant="h6"
                              style={{ fontWeight: "900", fontFamily: "Dosis" }}
                            >
                              Enter Mobile Number
                            </Typography>
                          </label>
                          <TextField
                            error={err}
                            style={{ width: "285px" }}
                            type="number"
                            helperText={err ? "Unathorized Access" : ""}
                            size="small"
                            onChange={(e) => setValue(e.target.value)}
                            onInput={(e) =>
                              (e.target.value = Math.max(
                                0,
                                parseInt(e.target.value).toString().slice(0, 10)
                              ))
                            }
                            InputProps={{
                              disableUnderline: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  +91-
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={value.length != 10}
                          background="red"
                          color="yellow"
                          variant="contained"
                          size="large"
                          style={{
                            width: "285px",
                            background: value.length == 10 ? "#663595" : "",
                            color: value.length == 10 ? "#fff" : "",
                          }}
                          // onClick={() => setIsFlipped((prev) => !prev)}
                          onClick={handleNumber}
                        >
                          Login
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    style={CardStyle}
                    // onClick={() => setIsFlipped((prev) => !prev)}
                  >
                    <Grid
                      container
                      spacing={2}
                      flexDirection="column"
                      justifyContent={"center"}
                      alignItems="center"
                    >
                      <Grid item>
                        <Grid
                          container
                          justifyContent={"center"}
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography
                              variant="body2"
                              style={{ color: "grey" }}
                            >
                              Enter OTP to verify your phone number
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <OtpInput
                          numInputs={4}
                          value={otp}
                          onChange={handleOtpInput}
                          inputStyle={{
                            width: "3rem",
                            height: "3rem",
                            margin: "0 10px",
                            fontSize: "16px",
                            borderRadius: 10,
                            border: "1px solid #663595",
                            // border: error
                            //   ? "1px solid rgba(0,0,0,0.3)"
                            //   : "1px solid red",
                            // color: "black",
                          }}
                        />
                      </Grid>
                      <Grid item>
                        {erro ? (
                          <FormHelperText style={{ color: "red" }}>
                            Please Enter correct Otp
                          </FormHelperText>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={handleOTP}
                          disabled={otp.length != 4}
                          variant="contained"
                          style={{
                            backgroundColor: otp.length == 4 ? "#663595" : "",
                            color: "#fff",
                            width: "260px",
                          }}
                        >
                          verify otp
                        </Button>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2">
                          {" "}
                          Didn't receive the code?{" "}
                          <span style={{ color: "red" }} onClick={handleResend}>
                            Resend OTP
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </ReactCardFlip>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Number;
