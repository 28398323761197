import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import CountUp from "react-countup";
//Import Icons
import FeatherIcon from "feather-icons-react";
import "../css/InvoiceWidget.css";
import { indianNumbers } from "../../Config/token";

const InvoiceWidget = ({ revenue, payout, margin }) => {
  return (
    <React.Fragment>
      <Row className="d-flex justify-content-around">
        <Col md={3}>
          <Card className="card-animate card_shadow card_body_widget">
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="fw-medium text-muted mb-0">Payout</p>
                </div>
                <div className="icon_css_total icon_css_total1">
                  <span className="counter-value ">
                    {/* <CountUp
                      style={{ color: "#663595", fontSize: "42px" }}
                      start={0}
                      end={payout}
                      decimals={0}
                      duration={2}
                    /> */}
                    {indianNumbers(payout, 0)}
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card className="card-animate card_shadow card_body_widget">
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="fw-medium text-muted mb-0">Revenue</p>
                </div>
                <div className="icon_css_total icon_css_total2">
                  <span className="counter-value ">
                    {indianNumbers(revenue, 0)}
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card className="card-animate card_shadow card_body_widget">
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="fw-medium text-muted mb-0">Margin</p>
                  {/* <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value text-primary">
                      <CountUp
                        start={0}
                        end={data?.total_amount}
                        decimals={0}
                        duration={2}
                      />
                    </span>
                  </h2> */}
                </div>
                <div className="icon_css_total icon_css_total3">
                  <span className="counter-value ">
                    {/* <CountUp
                      style={{
                        color: "#663595",
                        fontSize: "42px",
                        marginTop: "20px",
                      }}
                      start={0}
                      end={margin}
                      decimals={0}
                      duration={2}
                    /> */}
                    {indianNumbers(margin, 2)}
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default InvoiceWidget;
