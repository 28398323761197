import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { useLocation, useNavigate } from "react-router-dom";
import {
  createInvoice,
  getClient,
  get_sow,
  invoiceList,
  process_invoice,
  recon_invoice,
  update_billing_date,
} from "../../utils";
import "../teamdash/TeamDash.css";
import "../css/Invoice2022.css";
import MainDetailsPage from "./MainDetailsPage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VerifiedIcon from "@mui/icons-material/Verified";
import FeatherIcon from "feather-icons-react";
import Swal from "sweetalert2";
import Select from "react-select";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Button } from "reactstrap";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { dataToken, indianNumbers, token_type } from "../../Config/token";
import { Chip, Grid } from "@material-ui/core";
import InvoiceWidget from "./InvoiceWidget";
import Invoice2022Modal from "../Modal/Invoice2022Modal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

//stepper

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

createTheme("solarized", {
  text: {
    primary: "black",
    secondary: "#b2b2b2",
  },
  background: {
    default: "#fff",
  },
  boxshadow: {
    default: "5px 5px 30px #DEDEDEBF",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#f1f1f1",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});
function Invoice2022() {
  // const [stepperState, setStepperState] = React.useState(1) ;

  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#784af4",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        // backgroundImage:
        //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        backgroundColor: "#663595",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#663595",
        // backgroundImage:
        //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 5,
    height: 5,
    marginTop: 22,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "#663595",

      // backgroundImage:
      //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#663595",
      // backgroundImage:
      //   'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      // 1: <SettingsIcon />,
      // 2: <GroupAddIcon />,
      // 3: <VideoLabelIcon />,
      // 4: '',
      // 5: <VideoLabelIcon />,
      1: "",
      2: "",
      3: "",
      4: "",
      5: "",
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  // const steps = [
  //   "Invoice Created",
  //   "Payout added",
  //   "Billingdate Generated",
  //   "Billed",
  //   "Recon",
  // ];
  const steps = ["", "", "", "", ""];

  const ExpandedComponent = ({ data }) => {
    let count = 0;
    if (data.payout > 0) {
      count = 1;
    }
    if (!(data.start_date == "00-00-0000")) {
      count = 2;
    }
    if (data.billing_status == "billed") {
      count = 3;
    }
    if (data.is_completed == "yes") {
      count = 4;
    }
    // setStepperState(count)

    return (
      <div>
        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={count}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </div>
    );
  };
  const invoiceNo = Number(localStorage.getItem("invoiceId"));
  const [datalist, setDatalist] = useState([]);
  const [colDData, setColDData] = useState({});
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();

  const config = {
    headers: { "x-access-token": ` ${token}` },
  };

  const [showCI, setShowCI] = React.useState(false);

  const openModal = () => {
    setShowCI(true);
  };

  const closeModal = () => {
    setShowCI(false);
  };

  const [openEditInvoice, setOpenEditInvoice] = React.useState(false);
  const [sendData, sendDataEdit] = React.useState({});
  const openEditModal = (d) => {
    sendDataEdit(d);
    setOpenEditInvoice(true);
  };

  const closeEditInvoice = () => {
    setOpenEditInvoice(false);
  };

  const [open1, setOpen1] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [maxWidth2, setMaxWidth2] = React.useState("md");
  const [invId, setInvId] = React.useState("");
  const [clientData, setClientData] = React.useState([]);

  const handleClickOpen = (d) => {
    setInvId(d.invoice_id);
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleUpdate = () => {
    if (value == "" || value1 == "") return "";

    const postData = {
      start_date: value,
      end_date: value1,
      inv_id: invId,
    };
    axios
      .post(update_billing_date, postData, dataToken)
      .then((res) => {
        // window.location.reload();
        Swal.fire({
          icon: "success",
          title: "updated",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [value, setValue] = React.useState("");
  const [value1, setValue1] = React.useState("");
  const [minDate, setMinDate] = React.useState("");
  const [billingInvoiceId, setbillingInvoiceId] = React.useState("");
  const [reconInvoiceId, setreconInvoiceId] = React.useState("");
  const [margin, setMargin] = React.useState("");
  const [payout, setPayout] = React.useState("");
  const [revenue, setRevenue] = React.useState("");
  const [genInvForm, setGenInvForm] = React.useState({});
  const [getSow, setGetSow] = React.useState([]);

  const handleChange = (newValue) => {
    const date = Number(newValue.$D) < 10 ? `0${newValue.$D}` : newValue.$D;
    const dateTime = `${newValue.$y}-${newValue.$M + 1}-${date}`;

    setMinDate(newValue);

    setValue(dateTime);
  };

  const handleChange1 = (newValue) => {
    const date = Number(newValue.$D) < 10 ? `0${newValue.$D}` : newValue.$D;
    const dateTime2 = `${newValue.$y}-${newValue.$M + 1}-${date}`;

    setValue1(dateTime2);
  };

  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const [open3, setOpen3] = React.useState(false);

  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handlebilledDetails = () => {
    const postData = { inv_id: billingInvoiceId };
    if (location.pathname == "/invoice2023") {
      postData.year = 2023;
    } else if (location.pathname == "/invoice2022") {
      postData.year = 2022;
    }

    axios
      .post(process_invoice, postData, dataToken)
      .then((res) => {
        handleClose2();
        Swal.fire({
          icon: "success",
          text: "Submitted Successfully",
          iconColor: "#54B701",
          color: "#102464",
          confirmButtonColor: "#102464",
          title: "Success",
          confirmButtonText: "Done",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: `${err.response.data.message}`,
        });
      });
  };
  const reconcilInvoice = () => {
    const postData = { inv_id: reconInvoiceId };
    axios
      .post(recon_invoice, postData, dataToken)
      .then((res) => {
        handleClose3();
        Swal.fire({
          icon: "success",
          text: "Reconciled",
          iconColor: "#54B701",
          color: "#673695",
          confirmButtonColor: "#673695",
          title: "Success",
          confirmButtonText: "Done",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: `${err.response.data.message}`,
        });
      });
  };

  const getSowData = () => {
    axios
      .get(get_sow, dataToken)
      .then((res) => {
        setGetSow(res.data.sows);
      })
      .catch((err) => console.log(err, "err"));
  };

  useEffect(() => {
    console.log(location.pathname, "heck");
    const data = {
      year: location.pathname == "/invoice2023" ? "2023" : "2022",
    };
    axios
      .post(invoiceList, data, config)
      .then((res) => {
        setMargin(res.data.margin);
        setPayout(res.data.payout);
        setRevenue(res.data.revenue);
        setDatalist(res.data.invoices);
      })
      .catch((e) => {
        console.log(e);
      });
    getSowData();
  }, [location.pathname]);
  useEffect(() => {
    axios
      .get(getClient, config)
      .then((res) => {
        setClientData(res.data.client);
      })
      .catch((err) => console.log(err));
  }, []);

  const openInvoicePage = (e) => {
    localStorage.setItem("invoiceId", e.invoice_id);
    localStorage.setItem("invoiceNumber", e.invoice_number);
    console.log(e);
    navigate("/mainDetails", {
      state: {
        recon: e.is_completed,
        invoice_id_before: e.invoice_id,
        year: e.year,
      },
    });
  };

  const handleSubmitinvoicegen = (e) => {
    e.preventDefault();
    let compSow2023 = true;
    if (location.pathname == "/invoice2023") {
      genInvForm.year = 2023;
      compSow2023 = Object.keys(genInvForm).includes("sow_id");
    }
    genInvForm.is_manual = "yes";
    genInvForm.general_number = "998397";
    genInvForm.created_on = new Date();
    let compulsary = Object.keys(genInvForm).includes(
      "user_id",
      "start_date",
      "end_date",
      "due_date"
    );

    console.log(genInvForm, "genInvform");
    if (compulsary && compSow2023) {
      axios
        .post(createInvoice, genInvForm, dataToken)
        .then((res) => {
          closeModal();
          Swal.fire({
            position: "center",
            icon: "success",
            title: `success`,
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        })
        .catch((err) => {
          closeModal();
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: `${err.response.data.message}`,
          });
        });
    } else {
      closeModal();
      Swal.fire({
        icon: "warning",
        title: "Oops!",
        text: `please enter all the details.`,
      });
    }
  };

  const handleChangeinvoice = (e) => {
    console.log(e.label, e.value, "testinglabel", e);
    if (Number(e?.label?.split("-")[0])) {
      setGenInvForm({ ...genInvForm, sow_id: e.value });
    } else if (e.label) {
      setGenInvForm({ ...genInvForm, user_id: e.value });
    } else {
      const { name, value } = e.target;
      setGenInvForm({ ...genInvForm, [name]: value });
    }
  };

  const options = clientData;
  const colorStyles = {
    control: (styles) => ({ ...styles, height: "55px" }),
  };

  //menu
  const [anchorElA1, setAnchorElA1] = React.useState(null);
  const open = Boolean(anchorElA1);
  const handleClickA1 = (event, d) => {
    setColDData(d);
    setAnchorElA1(event.currentTarget);
  };
  const handleCloseA1 = () => {
    setAnchorElA1(null);
  };

  const columns = [
    {
      name: "S.No",
      selector: "invoice_id",
      cell: (d) => <div className="loanid">{d.invoice_id}</div>,
      sortable: true,
      width: "80px",
      center: true,
    },
    {
      name: "Inv No",
      selector: "invoice_number",
      cell: (d) => <div className="loanid">{d.invoice_number}</div>,
      sortable: true,
      width: "100px",
      center: true,
    },
    {
      name: "Billing DATE",
      selector: "billed_date",
      cell: (d) => <div className="date">{d.billed_date}</div>,
      sortable: true,
      width: "140px",
      center: true,
    },
    {
      name: "Company Name",
      selector: "companyname",
      cell: (d) => (
        <div className="colNmae2">
          {d.companyname}
          <span>
            &nbsp;{"("}
            {d.clientid}
            {")"}
          </span>
        </div>
      ),
      sortable: true,
      width: "250px",
      center: true,
    },
    {
      name: "Revenue",
      selector: "amount",
      cell: (d) => (
        <div className="amt_table">
          &#x20B9;{indianNumbers(d?.desc_amount, 2)}
        </div>
      ),
      sortable: true,
      width: "130px",
      center: true,
    },
    {
      name: "Payout",
      selector: "payout",
      cell: (d) => (
        <div className="amt_table">&#x20B9;{indianNumbers(d?.payout, 2)}</div>
      ),
      sortable: true,
      width: "130px",
      center: true,
    },
    {
      name: "Margin",
      selector: "margin",
      cell: (d) => (
        // <div className="amt_table">&#x20B9;{d?.margin?.toFixed(2)}</div>
        <div className="amt_table">&#x20B9;{indianNumbers(d?.margin, 2)}</div>
      ),
      sortable: true,
      width: "130px",
      center: true,
    },
    {
      name: "Margin Per",
      selector: "margin_per",
      cell: (d) => (
        <div className="amt_table">{d?.margin_per?.toFixed(2)}%</div>
      ),
      sortable: true,
      width: "120px",
      center: true,
    },
    {
      name: "billing date",
      selector: "upload",
      cell: (d) => (
        <div className="amt_table">
          {d.start_date == "00-00-0000" ? (
            <FeatherIcon
              icon="edit"
              style={{ color: "#663595", cursor: "pointer" }}
              onClick={() => handleClickOpen(d)}
            />
          ) : (
            <div>
              <span>
                {d.start_date_d}&nbsp;&nbsp;{d.end_date_d}
              </span>
            </div>
          )}
        </div>
      ),
      sortable: true,
      width: "150px",
      center: true,
    },
    // {
    //   name: "Process invoice",
    //   selector: "billing_status",
    //   cell: (d) => (
    //     <div className="amt_table">
    //       {d.billing_status == "billed" ? (
    //         <>
    //           <Button style={{ background: "#663595" }}>
    //             {d.billing_status}
    //           </Button>
    //         </>
    //       ) : (
    //         <>
    //           <Button
    //             style={{ background: "#663595" }}
    //             disabled={d.start_date == "00-00-0000" ? true : false}
    //             onClick={() => {
    //               setbillingInvoiceId(d.invoice_id);
    //               handleClickOpen2();
    //             }}
    //           >
    //             {d.billing_status}
    //           </Button>
    //         </>
    //       )}
    //     </div>
    //   ),
    //   sortable: true,
    //   width: "150px",
    //   center: true,
    // },
    // {
    //   name: "Recon",
    //   selector: "is_completed",
    //   cell: (d) => (
    //     <div className="amt_table">
    //       {d.is_completed == "yes" ? (
    //         <>
    //           <VerifiedIcon sx={{ color: "green" }} />
    //         </>
    //       ) : (
    //         <>
    //           <Button
    //             style={{ background: "#663595" }}
    //             disabled={d.payout > 0 ? false : true}
    //             onClick={() => {
    //               setreconInvoiceId(d.invoice_id);
    //               handleClickOpen3();
    //             }}
    //           >
    //             Recon
    //           </Button>
    //         </>
    //       )}
    //     </div>
    //   ),
    //   sortable: true,
    //   width: "120px",
    //   center: true,
    // },
    // {
    //   name: "Edit",
    //   // selector: "is_completed",
    //   cell: (d) => (
    //     <div className="amt_table">
    //       <FeatherIcon
    //         icon="edit"
    //         style={{ color: "#663595", cursor: "pointer" }}
    //         onClick={() => {
    //           openEditModal(d);
    //         }}
    //       />
    //     </div>
    //   ),
    //   sortable: true,
    //   width: "120px",
    //   center: true,
    // },
    // {
    //   name: "GUI",
    //   center: true,
    //   cell: (d) => <div>
    //     <ExpandedComponent data={d}/>
    //   </div>,
    // },
    {
      name: "Action",
      center: true,
      cell: (d) => (
        <div
          style={{ cursor: "pointer" }}
          id="demo-positioned-button"
          aria-controls={open ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(e) => handleClickA1(e, d)}
        >
          <MoreVertIcon />
        </div>
      ),
    },
  ];

  const dataTable = {
    columns: columns,
    data: datalist,
  };
  return (
    <div>
      <div>
        <div className="invoceheader2022">
          <div>
            {location.pathname.split("/")[1] == "mainDetails" && (
              <span onClick={() => navigate(-1)} className="backarrowButton">
                &nbsp;
                <ArrowBackIcon />
              </span>
            )}
          </div>
          <div className="headimg_team">
            {window.location.pathname != "/invoice2022" &&
            location.pathname != "/invoice2023" ? (
              <>
                Invoice &nbsp;
                <Chip
                  style={{
                    width: "70px",
                    fontSize: "17px",
                    background: "#663595",
                    fontWeight: 900,
                    color: "#fff",
                  }}
                  label={localStorage.getItem("invoiceNumber")}
                  variant="outlined"
                />
              </>
            ) : (
              <>Invoice</>
            )}{" "}
          </div>
        </div>

        {(location.pathname.split("/")[1] == "invoice2022" ||
          location.pathname.split("/")[1] == "invoice2023") && (
          <div className="m-2 invoicewidget">
            <InvoiceWidget revenue={revenue} payout={payout} margin={margin} />
          </div>
        )}

        {window.location.pathname === "/mainDetails" ? (
          <div
            style={{
              float: "right",
              alignItems: "center",
              textAlign: "end",
              marginTop: "7px",
              paddingRight: "20px",
              cursor: "pointer",
            }}
          >
            <a
              href={`https://farming-backend.taskmo.in/invtoken/downloadinvoice2022?year=2022&invoice_id=${invoiceNo}&token=${token}`}
              target="_blank"
            >
              <FileDownloadIcon />
            </a>
          </div>
        ) : (
          <></>
        )}
      </div>
      {location.pathname.split("/")[1] == "mainDetails" ? (
        <div>
          <MainDetailsPage />
        </div>
      ) : (
        <>
          <div className="dash_container">
            <div className="table_data">
              <div className="menu_container">
                <Button
                  className="createInvoicebutton2"
                  style={{ backgroundColor: "#663595" }}
                  onClick={openModal}
                >
                  Create Invoice
                </Button>
              </div>
              <div className="card_table">
                <DataTableExtensions
                  {...dataTable}
                  filterPlaceholder={`Search`}
                  className="extension_option"
                >
                  <DataTable
                    columns={columns}
                    data={datalist}
                    defaultSortFieldId={1}
                    pagination
                    paginationPerPage={7}
                    theme="solarized"
                    onRowClicked={openInvoicePage}
                    //for expanding each row
                    // expandableRows
                    // expandableRowExpanded={row =>row.invoice_id}
                    // expandableRowsComponent={ExpandedComponent}
                  />
                </DataTableExtensions>
              </div>
            </div>
          </div>
        </>
      )}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open1}
        onClose={handleClose1}
      >
        <DialogTitle>Update Date</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3} style={{ marginTop: "10px" }}>
              <DesktopDatePicker
                label="Start Date"
                inputFormat="DD/MM/YYYY"
                value={value}
                onChange={handleChange}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} />
                )}
              />
              <DesktopDatePicker
                label="End Date"
                inputFormat="DD/MM/YYYY"
                value={value1}
                onChange={handleChange1}
                minDate={value && minDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleUpdate}>
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open2}
        onClose={handleClose2}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Billing Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to Bill this transaction.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handlebilledDetails}>
            Yes
          </Button>
          <Button onClick={handleClose2} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open3}
        onClose={handleClose3}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Reconcile Invoice"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to Reconcil this Invoice.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={reconcilInvoice}>
            Yes
          </Button>
          <Button onClick={handleClose3} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        PaperProps={{ sx: { position: "fixed", top: 150, center: 10, m: 0 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth2}
        open={showCI}
        onClose={closeModal}
      >
        <DialogTitle>Generate Invoice</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitinvoicegen}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <label id="updateclientid">Update Client Id </label>
                <Select
                  styles={colorStyles}
                  id="updateclientid"
                  options={options}
                  isSearchable
                  onChange={handleChangeinvoice}
                />
              </Grid>

              {location.pathname == "/invoice2023" ? (
                <Grid item md={6}>
                  <label id="updateclientid">Sow </label>
                  <Select
                    styles={colorStyles}
                    id="sowid"
                    options={getSow}
                    isSearchable
                    onChange={handleChangeinvoice}
                  />
                </Grid>
              ) : (
                ""
              )}

              <Grid item md={6}>
                <label id="ponumber">PO Number</label>
                <TextField
                  fullWidth
                  name="po_no"
                  id="ponumber"
                  type="text"
                  variant="outlined"
                  onChange={handleChangeinvoice}
                />
              </Grid>

              <Grid item md={6}>
                <label id="podate">PO Date</label>
                <TextField
                  fullWidth
                  name="po_date"
                  id="podate"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeinvoice}
                />
              </Grid>

              <Grid item md={6}>
                <label id="podate1">Billing Start Date</label>
                <TextField
                  fullWidth
                  name="start_date"
                  id="podate1"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeinvoice}
                />
              </Grid>

              <Grid item md={6}>
                <label id="podate2">Billing End Date</label>
                <TextField
                  fullWidth
                  name="end_date"
                  id="podate2"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeinvoice}
                />
              </Grid>

              <Grid item md={6}>
                <label id="podate2"> Due Date</label>
                <TextField
                  fullWidth
                  name="due_date"
                  id="podate2"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChangeinvoice}
                />
              </Grid>
            </Grid>
            <Button
              style={{
                background: "#663595",
                float: "right",
                marginTop: "20px",
              }}
              type="submit"
            >
              Generate
            </Button>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      {openEditInvoice && (
        <Invoice2022Modal
          openEditInvoice={openEditInvoice}
          closeEditInvoice={closeEditInvoice}
          Editdata={sendData}
          clientData={clientData}
        />
      )}
      {/* main menu for action */}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorElA1}
        open={open}
        onClose={handleCloseA1}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          disabled={
            !(colDData.billing_status == "billed") &&
            colDData.start_date == "00-00-0000"
              ? true
              : false
          }
          onClick={() => {
            if (colDData.billing_status == "billed") {
              handleCloseA1();
            } else {
              setbillingInvoiceId(colDData.invoice_id);
              handleClickOpen2();
              handleCloseA1();
            }
          }}
        >
          <div>
            {colDData.billing_status == "billed" ? (
              <>
                <div>
                  <Grid
                    container
                    alignContent="center"
                    alignItems="cneter"
                    justifyContent="center"
                    spacing={1}
                  >
                    <Grid item>
                      <VerifiedIcon sx={{ color: "green" }} />
                    </Grid>
                    <Grid item>{colDData.billing_status}</Grid>
                  </Grid>
                </div>
              </>
            ) : (
              <>
                <Grid
                  container
                  alignContent="center"
                  alignItems="cneter"
                  justifyContent="center"
                  spacing={1}
                >
                  <Grid item>
                    {" "}
                    <ErrorOutlineIcon sx={{ color: "#CE8D3B" }} />
                  </Grid>
                  <Grid item> {colDData.billing_status}</Grid>
                </Grid>
              </>
            )}
          </div>
        </MenuItem>
        <MenuItem
          disabled={colDData.payout > 0 ? false : true}
          onClick={() => {
            if (colDData.is_completed == "yes" && colDData.payout > 0) {
              handleCloseA1();
            } else {
              setreconInvoiceId(colDData.invoice_id);
              handleClickOpen3();
              handleCloseA1();
            }
          }}
        >
          <div>
            {colDData.is_completed == "yes" ? (
              <>
                <Grid
                  container
                  alignContent="center"
                  alignItems="cneter"
                  justifyContent="center"
                  spacing={1}
                >
                  <Grid item>
                    <VerifiedIcon sx={{ color: "green" }} />
                  </Grid>
                  <Grid item>Recon</Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  container
                  alignContent="center"
                  alignItems="cneter"
                  justifyContent="center"
                  spacing={1}
                >
                  <Grid item>
                    <ErrorOutlineIcon sx={{ color: "#CE8D3B" }} />
                  </Grid>
                  <Grid item>Recon</Grid>
                </Grid>
                {/* <Button
                  style={{ background: "#663595" }}
                  disabled={colDData.payout > 0 ? false : true}
                >
                  <ErrorOutlineIcon sx={{ color: "#CE8D3B" }} />  Recon
                </Button> */}
              </>
            )}
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseA1();
            openEditModal(colDData);
          }}
        >
          <div className="amt_table">
            <FeatherIcon
              icon="edit"
              style={{ color: "#663595", cursor: "pointer" }}
            />
          </div>{" "}
          &nbsp; Edit
        </MenuItem>
      </Menu>
    </div>
  );
}

export default Invoice2022;
