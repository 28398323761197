import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import CountUp from "react-countup";
//Import Icons
import FeatherIcon from "feather-icons-react";
import "./Widget.css";

const WidgetTsmSpNo = ({ data }) => {
  return (
    <React.Fragment>
      <Row>
        <Col md={4}>
          <Card className="card-animate card_shadow">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-medium text-muted mb-0">Total FSE</p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value text-primary">
                      <CountUp
                        start={0}
                        end={data?.no_of_users}
                        decimals={0}
                        duration={2}
                      />
                    </span>
                  </h2>
                </div>
                <div className="icon_css_total">
                  <FeatherIcon
                    icon="users"
                    style={{ color: "#673695" }}
                    size={50}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="card-animate card_shadow">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-medium text-muted mb-0">Total Leads</p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value text-primary">
                      <CountUp
                        start={0}
                        end={data?.total_leads}
                        decimals={0}
                        duration={2}
                      />
                    </span>
                  </h2>
                </div>
                <div className="icon_css_total">
                  <FeatherIcon
                    icon="briefcase"
                    style={{ color: "#673695" }}
                    size={50}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* <Col md={4}>
          <Card className="card-animate card_shadow">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-medium text-muted mb-0">No Of SOW's </p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span
                      className="counter-value text-primary"
                      // style={{ color: "#DFA800" }}
                    >
                      <CountUp
                        start={0}
                        end={data?.no_of_sow}
                        decimals={0}
                        duration={2}
                      />
                    </span>
                  </h2>
                </div>
                <div className="icon_css_total">
                  <FeatherIcon
                    icon="layers"
                    style={{ color: "#673695" }}
                    size={50}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col> */}

        <Col md={4}>
          <Card className="card-animate card_shadow">
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fw-medium text-muted mb-0">Total Amount</p>
                  <h2 className="mt-4 ff-secondary fw-semibold">
                    <span className="counter-value text-primary">
                      <CountUp
                        start={0}
                        end={data?.total_amount}
                        decimals={0}
                        duration={2}
                      />
                    </span>
                  </h2>
                </div>
                <div className="icon_css_total">
                  <span style={{ color: "#673695", fontSize: "60px" }}>
                    &#8377;
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default WidgetTsmSpNo;
