import React from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Button } from "@mui/material";
import "../pages/splitrequest.css";
import moment from "moment";

const GetDataData = ({ data, minDate1, checkdata ,maxDate1,value,dis}) => {
  console.log(value,'fasakvalue')
  var daylist;
  console.log(data, minDate1, "faskafasa");
  let currentData = JSON.parse(sessionStorage.getItem("currentData"));
  let minStartDate = currentData.start_date;
  let minEndDate = currentData.end_date;
  const [startdate, setStartdate] = React.useState("");
  const [enddate, setEnddate] = React.useState("");
  const [updated, setUpdated] = React.useState(false);



  const handleChangestart = (newValue) => {
    console.log(startdate, enddate, "fasdint");
    setStartdate(newValue);
    storeDatesfirst(newValue);
  };

  const handleChangeend = (newValue) => {
    setEnddate(newValue);
    storeDates(newValue);
  };

  const storeDates = (newValue) => {
    let datein1 = `${startdate.$y}-${Number(startdate.$M) + 1}-${startdate.$D}`;
    let datein2 = `${newValue.$y}-${Number(newValue.$M) + 1}-${newValue.$D}`;
    console.log(localStorage.getItem("fasakfasak"), "fasakfask");
    if (!localStorage.getItem("dates")) {
      localStorage.setItem("dates", JSON.stringify([]));
    }
    let ot = JSON.parse(localStorage.getItem("dates"));
    console.log(ot, "fasakot");
    let obj = {};

    obj.inv_id = data;
    obj.start_date = datein1;
    obj.end_date = datein2;

    ot = ot.filter((item) => item.inv_id != data);

    ot.push(obj);

    console.log(ot, "checkot");

    const start = ot.map((o) => o.start_date);
    console.log(start, "start");

    localStorage.setItem("dates", JSON.stringify(ot));
  };

  const storeDatesfirst = (newValue) => {
    if (enddate == "") return "";
    let datein1 = `${newValue.$y}-${Number(newValue.$M) + 1}-${newValue.$D}`;
    let datein2 = `${enddate.$y}-${Number(enddate.$M) + 1}-${enddate.$D}`;
    console.log(localStorage.getItem("fasakfasak"), "fasakfask");
    if (!localStorage.getItem("dates")) {
      localStorage.setItem("dates", JSON.stringify([]));
    }
    let ot = JSON.parse(localStorage.getItem("dates"));
    console.log(ot, "fasakot");
    let obj = {};

    obj.inv_id = data;
    obj.start_date = datein1;
    obj.end_date = datein2;

    ot = ot.filter((item) => item.inv_id != data);
    console.log(ot, "checkot");
    ot.push(obj);

    localStorage.setItem("dates", JSON.stringify(ot));
  };
  const handleSubmitDate = () => {
    let datein1 = `${startdate.$y}-${Number(startdate.$M) + 1}-${startdate.$D}`;
    let datein2 = `${enddate.$y}-${Number(enddate.$M) + 1}-${enddate.$D}`;
    console.log(localStorage.getItem("fasakfasak"), "fasakfask");
    if (!localStorage.getItem("dates")) {
      localStorage.setItem("dates", JSON.stringify([]));
    }
    let ot = JSON.parse(localStorage.getItem("dates"));
    console.log(ot, "fasakot");
    let obj = {};

    obj.inv_id = data;
    obj.start_date = datein1;
    obj.end_date = datein2;

    ot = ot.filter((item) => item.inv_id != data);
    console.log(ot, "checkot");
    ot.push(obj);

    localStorage.setItem("dates", JSON.stringify(ot));
  };
  var getDaysArray = function (start, end) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      let ot = String(new Date(dt)).split(" ").slice(1, 4).join("-");
      arr.push(moment(ot).format("MMM-DD-YYYY"));
    }
    return arr;
  };
  function disableWeekends(e) {
    // return true;
    let c = e;
   
    // c = c.$d.split(' ')
    let d = String(c.$d).split(" ").slice(1, 4).join("-");
    console.log(d,'harvey specter');
    let arr = [];
    let blackoutDates = ["Feb-04-2023", "Feb-05-2023"];

    // console.log(d.join('-'),blackoutDates[0],'checkeeee')
    let dt = JSON.parse(localStorage.getItem("dates"));
    // console.log(dt,'check')
    let miblock = [];
    if (dt && dt.length > 0) {
      for (var i = 0; i < dt.length; i++) {
        let it = dt[i];
        daylist = getDaysArray(
          new Date(dt[i].start_date),
          new Date(dt[i].end_date)
        );
        miblock.push(...daylist);
      }
      console.log('miblock',miblock)
    }
    // console.log(moment(new Date('2023-1-14')).format('MM-DD-YYYY'),'fasak')
    // console.log(miblock,d,'finalcheck')
    // let otcheck = daylist.includes(checkdata[0].date)
    // let otcheck = checkdata.reduce((acc, i) => daylist.includes(i.date)?acc+Number(i):acc+0, 0);
    console.log(checkdata, "fasakone");



    
   console.log(checkdata,'fasak3')


    if (miblock) {
      return miblock.includes(d);
    } 
    // else {
    //   return false;
    // }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <DesktopDatePicker
            label="Start Date"
            inputFormat="YYYY/MM/DD"
            value={startdate}
            // minDate={minStartDate}
            minDate={minDate1}
            maxDate={maxDate1}
            shouldDisableDate={disableWeekends}
            // shouldDisableDate={disableRandomDates}
            onChange={handleChangestart}
            disabled={!dis}
            renderInput={(params) => (
              <TextField
                {...params}
                error={false}
               
                size="small"
                sx={{
                  width: 250,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <DesktopDatePicker
            label="End Date"
            inputFormat="YYYY/MM/DD"
            minDate={startdate} //checking the minimum date
            maxDate={maxDate1}
            value={enddate}
            disabled={!dis}
            onChange={handleChangeend}
            shouldDisableDate={disableWeekends}
            renderInput={(params) => (
              <TextField
                {...params}
                error={false}
                size="small"
                sx={{
                  width: 250,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <span style={{marginLeft:'-20px',fontWeight:600}}>leads</span>: <>{startdate!=='' && enddate!==''&&<>{value?.total_leads||''}</>}</>
        </Grid>
        <Grid item>
          {/* {JSON.stringify({ startdate, enddate })}
          {JSON.stringify(JSON.parse(localStorage.getItem("dates")))} */}
          {/* <Button
            disabled={startdate == "" || enddate == ""}
            onClick={handleSubmitDate}
            variant="contained"
            
            // style={{
            //   background: JSON.parse(localStorage.getItem("dates"))?.map(
            //     (item,i,arr) =>
            //       item.inv_id == data
            //         ? item.start_date!='null' && item.end_date!='null'
            //           ? "green"
            //           : "#1665C0"
            //         : "#1665c0"
            //   ),
            // }}
          >
            Submit Date
          </Button> */}
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default GetDataData;
