import { TextField } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import OtpInput from "react-otp-input";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { login_number, otp_number } from "../../utils";
import { FinanceContext } from "../../App";
import Swal from "sweetalert2";
// import { motion } from "framer-motion/dist/framer-motion";

const variants = {
  rotate: { rotate: [0, -30, 0], transition: { duration: 0.5 } },
  stop: { y: [0, -10, 0], transition: { repeat: Infinity, repeatDelay: 3 } },
};

function Otp({ assignLogin }) {
  const navigate = useNavigate();
  const [context, setContext] = useContext({ ...FinanceContext });
  const [shake, setShake] = useState(false);

  const [otp, setOtp] = useState("");
  const [error, setError] = useState(true);

  useEffect(() => {
    if (context.otp_mobile.length === 0) {
      navigate("/login");
    }
  }, []);

  const handleOtpInput = (e) => {
    setOtp(e);
  };
  // const handleVerifyOtp = () => {
  // setVerify(false);
  // setOpenOtp(false);
  // };

  const [counter, setCounter] = useState(59);
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const handleOTP = () => {
    
    const dataInput = {
      mobile_number: context.otp_mobile,
      otp: otp,
    };

    

    axios
      .post(otp_number, dataInput)
      .then((res) => {
        
        if (res.data.error) {
          setError(false);
          setShake(true);
          setTimeout(() => {
            setShake(false);
          }, 1000);
        } else {
          
          localStorage.setItem("finance_email", res.data.token);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("type", res?.data?.users?.type);
          localStorage.setItem("finance_id", res.data.users.id);
          localStorage.setItem("full_name", res.data.users.full_name[0]);
          navigate("/invoice2022");
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleResend = () => {
    const dataNumber = {
      mobile_number: `${context.otp_mobile}`,
    };
    axios
      .post(login_number, dataNumber)
      .then((res) => {
        setError(true);
        setCounter(59);

        Swal.fire({
          position: "center",
          icon: "success",
          title: "OTP has been send to the registered Number",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

 

  return (
    <div className="p_login">
      <div className="log_col1">
        <img
          src={window.location.origin + "/images/logopurple.svg"}
          alt="login"
          className="logo_css_purp"
        />
        <img
          src={window.location.origin + "/images/logo.svg"}
          alt="login"
          className="login_bg"
          data-aos="fade-right"
          data-aos-duration="600"
        />
      </div>
      <div className="log_col22">
        {/* <h1 className="log_name">Finance Dashboard</h1> */}
        {/* <h2 className="log_sub"> Dashboard</h2> */}
        <div className="log_col22 col-md-6">
          <h1 className="log_name">Finance Dashboard</h1>
          {error ? (
            <p className="log_p2 log_p21">
              We are sending you an OTP to verify your phone number
            </p>
          ) : (
            ""
            // <motion.p
            //   animate={{
            //     textAlign: "center",
            //     x: [0, 0, 0, 0, 100, 0],
            //     transition: { duration: 0.5 },
            //   }}
            //   className="log_p2"
            //   style={{ color: "red", fontSize: "18px", fontWeight: "600" }}
            // >
            //   Invalid OTP
            // </motion.p>
          )}
          <div className="mt-2">
            <div className="opt_input">
              <OtpInput
                numInputs={4}
                value={otp}
                onChange={handleOtpInput}
                inputStyle={{
                  width: "3rem",
                  height: "3rem",
                  margin: "0 10px",
                  fontSize: "16px",
                  borderRadius: 4,
                  border: error ? "1px solid rgba(0,0,0,0.3)" : "1px solid red",
                  color: "black",
                }}
              />
              <button
                className="button_verify"
                style={{ width: "250px" }}
                onClick={handleOTP}
              >
                Verify OTP
              </button>
            </div>
          </div>
          <div className="timer_box">
            {/* <img
            src={window.location.origin + "/images/time.svg"}
            alt="ott"
            className="timer_logo"
          />
          <p className="timer_text">00:{counter}</p> */}
          </div>

          <div>
            <p className="otp_query">
              Didn't receive the code?
              <span
                className={counter === 0 ? "otp_resend" : "otp_resend1"}
                onClick={handleResend}
              >
                Resend OTP
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Otp;
