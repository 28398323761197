import React, { useEffect, useState } from "react";
import "../css/agreement.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import axios from "axios";
import { dataToken } from "../../Config/token";
import { getAgreement, getEinvoice, upload_agreement } from "../../utils";
import Swal from "sweetalert2";
import { PDFViewer } from "react-view-pdf";
function Agreement() {
  const id = localStorage.getItem("invoiceId");
  const [agreement, setAgreement] = useState("");
  const [einvoice, setEinvoice] = useState("");
  const imageUpload = (e, type) => {
    const axiosData = new FormData();
    axiosData.append("file", e.target.files[0]);
    axiosData.append("id", id);
    axiosData.append("type", type);
    axios
      .post(upload_agreement, axiosData, dataToken)
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `success`,
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      })
      .catch((e) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `something went wrong`,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };
  useEffect(() => {
    const postData = {
      inv_id: id,
    };
    axios
      .post(getAgreement, postData, dataToken)
      .then((res) => {
        setAgreement(res.data.agreement);
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .post(getEinvoice, postData, dataToken)
      .then((res) => {
        setEinvoice(res.data.e_invoice);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  return (
    <div>
      <div className="add_agreement">
        {agreement ? (
          <button className="button_agree">
            <CloudDoneIcon className="mr-2" />
            Agreement Updated
          </button>
        ) : (
          <label className="button_agree">
            <CloudUploadIcon className="mr-2" />
            Agreement
            <input
              type="file"
              onChange={(e) => {
                imageUpload(e, "agreement");
              }}
            />
          </label>
        )}
        {einvoice ? (
          <button className="button_agree mr-2">
            <CloudDoneIcon className="mr-2" />
            E-Invoice Updated
          </button>
        ) : (
          <label className="button_agree mr-2">
            <CloudUploadIcon className="mr-2" />
            E-Invoice
            <input
              type="file"
              onChange={(e) => {
                imageUpload(e, "e_invoice");
              }}
            />
          </label>
        )}
      </div>
      <div className="mt-5 agreement_box">
        <PDFViewer url={einvoice} />
        <PDFViewer url={agreement} />
      </div>
    </div>
  );
}

export default Agreement;
