import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./FseDetailsTable.css";
import Avatar from "@mui/material/Avatar";
import { Grid, Typography } from "@mui/material";
import WidgetTsmSp from "./WidgetTsmSp";
import WidgetTsmSpNo from "./widgetTsmSpNo";

createTheme("solarized", {
  text: {
    primary: "black",
    secondary: "#b2b2b2",
  },
  background: {
    default: "#fff",
  },
  boxshadow: {
    default: "5px 5px 30px #DEDEDEBF",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#f1f1f1",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});
function Dropdownone({ data }) {
  return (
    <Grid>
      <Grid
        container
        sx={{
          border: "1px solid #000",
          height: "120px",
          borderRadius: "5px",
          padding: "10px 30px",
          width: "85%",
          margin: "20px 7.5% 20px 7.5%",
        }}
        direction="column"
        justifyContent={"left"}
        textAlign="left"
      >
        <Grid item>
          <Typography variant="h6">
            <span style={{ borderBottom: "4px solid #54b701" }}>
              User Account Details:
            </span>
          </Typography>
        </Grid>
        <Grid item sx={{ marginTop: "10px" }}>
          <Grid
            container
            direction={"row"}
            justifyContent="space-between"
            spacing={1}
          >
            <Grid item>
              <Grid container direction="column">
                <Grid item style={{ borderBottom: "1px solid #f1f1f1" }}>
                  Bank Account Name{" "}
                </Grid>
                <Grid item>
                  {" "}
                  <span style={{ fontWeight: "900" }}>{data.account_name}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item style={{ borderBottom: "1px solid #f1f1f1" }}>
                  Bank Account Number{" "}
                </Grid>
                <Grid item>
                  {" "}
                  <span style={{ fontWeight: "900" }}>
                    {data.account_number}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column">
                <Grid item style={{ borderBottom: "1px solid #f1f1f1" }}>
                  IFSC Code{" "}
                </Grid>
                <Grid item>
                  <span style={{ fontWeight: "900" }}>{data.ifsc_code}</span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function NoFoTable({ data }) {
    
  const [datalist, setDatalist] = useState([]);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  const config = {
    headers: { "x-access-token": ` ${token}` },
  };

  const openInvoicePage = (e) => {
    localStorage.setItem("invoiceId", e.invoice_id);
    console.log(e);
    navigate("/mainDetails");
  };

  const columns = [
    {
      name: "Profile",
      selector: (d) => d.profile_image,
      sortable: true,
      center: true,
      width: "200px",
      cell: (d) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5px",
            gap: "12px",
          }}
        >
          <div
            style={{
              border:
                d.onboard_status == "none"
                  ? "2px solid #F4C459"
                  : d.onboard_status == "onboarded"
                  ? "2px solid green"
                  : d.onboard_status == "inactive"
                  ? "2px solid red"
                  : "2px solid blue",
              width: "70px",
              height: "70px",
              borderRadius: "35px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ zIndex: "100" }}>
              <Avatar
                alt="Profile"
                sx={{ width: 56, height: 56 }}
                src={
                  d.profile_image.length > 5
                    ? d.profile_image.substr(0, 4) === "http"
                      ? d.profile_image
                      : `https://isp.taskmo.in/fieldon_images/${d.profile_image}`
                    : window.location.origin + "/images/default_profile.svg"
                }
                className="profile_table_images"
              />
            </div>
          </div>
          {/* <div className="name_box">
              <p className="name">UserId:{d.user_id}</p>
            </div> */}
        </div>
      ),
    },
    {
      name: "User id",
      selector: "user_id",
      cell: (d) => <div className="colName">{d.user_id}</div>,
      sortable: true,
      // center: true,
    },
    {
      name: "Request id",
      selector: "request_id",
      cell: (d) => <div className="colName">{d.request_id}</div>,
      sortable: true,
      // center: true,
    },
    {
      name: "Name",
      selector: "fse",
      cell: (d) => <div className="colName">{d.fse}</div>,
      sortable: true,
      center: true,
    },
    {
      name: "Mobile Number",
      selector: "fse_mob",
      cell: (d) => <div className="colNmae">{d.fse_mob}</div>,
      sortable: true,
      center: true,
    },
    {
      name: "Pan Number",
      selector: "pan_number",
      cell: (d) => <div className="colNmae">{d.pan_number}</div>,
      sortable: true,
      center: true,
    },
    {
      name: "DOJ",
      selector: "doj",
      cell: (d) => <div className="amt_table">{d.doj}</div>,
      sortable: true,
      center: true,
    },
    {
      name: "Lead Count",
      selector: "total_leads",
      cell: (d) => <div className="amt_table">{d.total_leads}</div>,
      sortable: true,
      center: true,
    },
    {
      name: "CPL",
      selector: "cpl",
      cell: (d) => <div className="amt_table">{d.cpl}</div>,
      sortable: true,
      center: true,
    },
    {
      name: "AMOUNT",
      selector: "total_payout",
      cell: (d) => <div className="amt_table">&#x20B9;{d.total_payout}</div>,
      sortable: true,
      center: true,
    },
    // {
    //   name: "Payment Status",
    //   selector: "number",
    //   cell: (d) => <div>{"testing"}</div>,
    //   // cell: (d) => {
    //   //   return (
    //   //     <>
    //   //       <img
    //   //         src={window.location.origin + "/images/ic_eye.svg"}
    //   //         alt="ott"
    //   //         className="paper"
    //   //         onClick={() => {
    //   //           handleModel(d);
    //   //         }}
    //   //       />
    //   //       <InvoiceModal
    //   //         handleClose={handleClose}
    //   //         open={open}
    //   //         inData={inData}
    //   //         innId={innId}
    //   //         year={"22-23"}
    //   //       />
    //   //     </>
    //   //   );
    //   // },
    //   sortable: true,
    //   center: true,
    // },
  ];
  const dataTable = {
    columns,
    data: data && data?.leads,
  };

  return (
    <div>
      <WidgetTsmSpNo data={data} />
      <>
        <div className="dash_container">
          <div className="table_data">
            <div className="card_table">
              <DataTableExtensions
                {...dataTable}
                filterPlaceholder={`Search`}
                className="extension_option"
              >
                <DataTable
                  columns={columns}
                  data={data?.leads}
                  defaultSortFieldId={1}
                  pagination
                  paginationPerPage={5}
                  theme="solarized"
                  expandableRows={false}
                  expandableRowsComponent={(d) => Dropdownone(d)}
                  expandableRowsHideExpander
                  expandOnRowClicked={true}
                />
              </DataTableExtensions>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default NoFoTable;
