import React, { useRef, useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  Grid,
  MenuItem,
  Button,
  Snackbar,
  Typography,
  CardActions,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Select from "react-select";
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { dataToken } from "../../Config/token";
import { bulkaddpayreq, getInvoices, getSplitDate } from "../../utils";
import axios from "axios";
import "../pages/splitrequest.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { InsertEmoticon } from "@material-ui/icons";
import GetDataData from "./GetDataData";
import Swal from "sweetalert2";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const SplitRequest = () => {
  // const [arrOut,setArrOut] = useState([])
  const dateLength = JSON.parse(localStorage.getItem('dates'))
  let test = [];
  let arrOut = [];
  const location = useLocation();
  const [otData, setOtData] = useState([]);
  let currentData = JSON.parse(sessionStorage.getItem("currentData"));
  const navigate = useNavigate();
  const { id } = useParams();

  const [openalert, setOpenAlert] = React.useState(false);
  const [openalert2, setOpenAlert2] = React.useState(false);
  const [openalert3, setOpenAlert3] = React.useState(false);
  const [openalert4, setOpenAlert4] = React.useState(false);
  const [errd, setErrd] = React.useState(false);

  const [getinv, setGetinv] = React.useState([]);
  const [splitnum, setSplitNum] = React.useState(0);
  const [splitreq, setSplitReq] = React.useState({});
  const [dateData, setDateData] = React.useState([]);
  const [inv, setInv] = React.useState({});
  const [done, setDone] = React.useState(false);
  const splitreqValue = splitreq?.value;
  const [testData, setTestData] = useState([]);
  const [reqselect, setReqSelect] = useState(false);
  const [doneSelecting, setDoneSelecting] = useState(false);
  const [mainMinDate, setMainMinDate] = useState("");
  const [mainMaxDate, setMainMaxDate] = useState("");
  const [changeButton, setChangeButton] = useState(false);


  //checking


  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

    // console.log(splitnum, "123");
  //   console.log(splitreq?.value, "reqqq");
  //   console.log(dateData, "tabledata");
  console.log(inv, "invvvv");
  console.log(splitreqValue, "hiii");
  React.useEffect(() => {
    axios
      .get(getInvoices, dataToken)
      .then((res) => setGetinv(res.data.invoice))
      .catch((err) => console.log(err));
  }, []);

  var getDaysArray = function (start, end) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      let ot = String(new Date(dt)).split(" ").slice(1, 4).join("-");
      arr.push(moment(ot).format("DD-MMM-YYYY"));
    }
    return arr;
  };

  React.useEffect(() => {
    const body = {};
    body.req_id = id;
    axios
      .post(getSplitDate, body, dataToken)
      .then((res) => {
        setDateData(res.data.alldata.datedata);
        setMainMaxDate(res.data.alldata.max_date);
        setMainMinDate(res.data.alldata.min_date);
        setTestData(res.data.alldata.datedata);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleClick = () => {
    setOpenAlert(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };
  const handleClose2 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert2(false);
  };
  const handleClose3 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert3(false);
  };

  const handleClose4 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert4(false);
  };

  const handleSplitSelect = (e) => {
    setSplitNum(e.target.value);
  };

  const handleSplitReqChange = (value) => {
    setSplitReq({ value });
  };

  const handleInvoiceChange = (e, d) => {
    // console.log(d, e.target.value, "ddd");
    // console.log(testData, "ddd");
    const ot = testData.map((item) =>
      item.slno == d.slno ? { ...item, inv_id: e.target.value } : { ...item }
    );
    setTestData(ot);

    // console.log(testData, "ddd");
  };

  const handleInvDrop = () => {
    setDone(!done);
    if (splitnum !== 0) {
      setOpenAlert(true);
    } else {
      setOpenAlert(false);
    }
    setReqSelect(true);
  };

  const reqSelect = [
    {
      value: "1",
      label: "1",
    },
    {
      value: "2",
      label: "2",
    },
    {
      value: "3",
      label: "3",
    },
    {
      value: "4",
      label: "4",
    },
    {
      value: "5",
      label: "5",
    },
    {
      value: "6",
      label: "6",
    },
    {
      value: "7",
      label: "7",
    },
    {
      value: "8",
      label: "8",
    },
    {
      value: "9",
      label: "9",
    },
    {
      value: "10",
      label: "10",
    },
  ];

  //   React Select options

  const options = getinv;
  const colorStyles = {
    control: (styles) => ({
      ...styles,

      // splitreq?.value?.length <= 3
      //   ? "55px"
      //   : splitreq?.value?.length > 3
      //   ? "100px"
      //   : "55px",
    }),
  };

  //   data table columns

  const columns = [
    {
      name: "Date",
      selector: (d) => d.date,
      cell: (d) => <div>{d.date}</div>,
      sortable: true,
      width: "120px",
      center: true,
    },
    {
      name: "Leads",
      selector: (d) => d.leads,
      cell: (d) => <div>{d.leads}</div>,
      sortable: true,
      width: "120px",
      center: true,
    },
    // {
    //   name: "Invoice Dropdown",
    //   //   selector: "Invoice Dropdown",
    //   cell: (d) => (
    //     <div>
    //       <TextField
    //         select
    //         size="small"
    //         sx={{width:300}}
    //         onChange={(e) => handleInvoiceChange(e, d)}
    //         SelectProps={{
    //           MenuProps: {
    //             // sx: { maxHeight: "50%" },
    //           },
    //         }}
    //       >
    //         {done &&
    //           splitreq?.value?.map((options) => (
    //             <MenuItem key={options.value} value={options.value}>
    //               {options.label}
    //             </MenuItem>
    //           ))}
    //       </TextField>
    //     </div>
    //   ),
    //   sortable: true,
    //   // width: "120px",
    //   center: true,
    // },
  ];

  const tableData = {
    columns: columns,
    data: dateData,
  };

  const handleSplitSubmit = () => {
    if (!changeButton) {
      return handleDtData();
    }
    if (!errd) {
      // console.log(otData, "otData");
    } else {
      setOpenAlert3(true);
      return''
    }
    // }
    // console.log(testData, "testdata");
    // const ot = testData.find((item) => item.inv_id == "0");
    // console.log(ot, "testfinale");

    // if (!ot) {
    //   console.log("success");
    //   setOpenAlert2(true);
    //   let submitdata = {};
    //   for (let i = 0; i < testData.length; i++) {
    //     if (submitdata[testData[i].inv_id] == undefined) {
    //       console.log(testData[i], "faskcheckingchecking");
    //       submitdata[testData[i].inv_id] = Number(testData[i].leads);
    //     } else {
    //       submitdata[testData[i].inv_id] += Number(testData[i].leads);
    //     }
    //   }
    //   let invID = Object.keys(submitdata);
    //   let leadsdata = Object.values(submitdata);
    //   let mainot = [];
    //   for (var i = 0; i < leadsdata.length; i++) {
    //     let obj1 = {};
    //     obj1.inv_id = invID[i];
    //     obj1.total_leads = leadsdata[i];
    //     obj1.req_id = id;
    //     mainot.push(obj1);
    //   }
    //   let ard = JSON.parse(localStorage.getItem("dates"));
    //   if (!ard || ard.length !== Number(splitnum)) {
    //     //

    //     return setOpenAlert4(true);
    //   }

    //   let arrDates = JSON.parse(localStorage.getItem("dates")).sort(
    //     (a, b) => a.inv_id - b.inv_id
    //   );

    //   console.log(mainot, arrDates, "faskafasak");
    //   let main1 = mainot.sort((a, b) => a.inv_id - b.inv_id);

    //   let finalArray = main1.map((item, i) =>
    //     Object.assign({}, item, arrDates[i])
    //   );
    //   console.log(finalArray, "faskafasak");

    axios
      .post(bulkaddpayreq, otData, dataToken)
      .then((res) => {
        if (res.data.error) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Invoice has been split successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `${res.data.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/request");
        }
      })
      .catch((err) => {
        // console.log(err, "err");
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${err.data.message}`,
          showConfirmButton: false,
          timer: 1500,
        });
      });
    
  };

  const handleDtData = () => {
    // console.log(dateData, "faak");
    let datedatal = JSON.parse(localStorage.getItem("dates"));
    // console.log(datedatal, "datadates");

    for (let i = 0; i < datedatal.length; i++) {
      // let test = []
      let datesone = getDaysArray(
        datedatal[i].start_date,
        datedatal[i].end_date
      );
      // console.log(dateData, "faak", datesone);
      let obj = {};
      let leads = 0;
      for (let j = 0; j < datesone.length; j++) {
        for (let k = 0; k < dateData.length; k++) {
          // console.log(dateData[k],'fasak')
          if (dateData[k].date == datesone[j]) {
            // console.log(dateData[0], "testing");
            leads += Number(dateData[k].leads);
          }
        }
      }
      obj = { ...datedatal[i], leads, req_id: id };
      obj["total_leads"] = obj["leads"];
      delete obj["leads"];

      test.push(obj);
      // console.log(obj);
      setChangeButton(true);
    }
    let ct = dateData.reduce((acc, sum) => acc + Number(sum.leads), 0);
    let ctgot = test.reduce((acc, sum) => acc + Number(sum.total_leads), 0);
    if (ct == ctgot) {
      // console.log("data  matching", "fasaktesting");
    } else {
      // console.log("data not matching", "fasaktesting");
    }
    // setArrOut(test)
    // console.log(test, "fasaktesting", ct, ctgot);
    if (ct != ctgot) {
      setErrd(true);
    } else {
      setErrd(false);
    }
    setOtData(test);
  };

  return (
    <div style={{ marginTop: "16px" }}>
      <Grid
        container
        xs={12}
        justifyContent="space-between"
        padding={"13px"}
        sx={{
          border: ".6px solid #663595",
          margin: "10px 14px",
          width: "98%",
          backgroundColor: "#EFF8F6",
          borderRadius: "7px",
        }}
      >
        <Grid item>
          <Grid container direction="row">
            <Grid item>SOW ID : </Grid>

            <Grid item>&nbsp;{currentData?.sow_id}</Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row">
            <Grid item>Leads : </Grid>

            <Grid item>&nbsp;{currentData?.leads}</Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row">
            <Grid item>Cost Per Lead : </Grid>

            <Grid item>&nbsp;{currentData?.p_cpl}</Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row">
            <Grid item>Date : </Grid>

            <Grid item>&nbsp;{currentData?.display_date}</Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row">
            <Grid item>Requested by : </Grid>

            <Grid item>&nbsp;{currentData?.req_name}</Grid>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Grid
          container
          spacing={{ xs: 1, md: 1 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          // style={{ paddingLeft: "10px" }}
        >
          {dateData.map((_, index) => (
            <Grid
              item
              xs={2}
              sm={4}
              md={1.78}
              key={index}
              sx={{ margin: "10px 14px" }}
            >
              <div
                style={{
                  marginBottom: "0px",
                  border: "1px solid #663595",
                  background: "#EFF8F6",
                  height: "40px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#000",
                  padding: "5px",
                  width: "200px",
                }}
              >
                {_.date}: &nbsp;
                <span style={{ fontWeight: "900", fontSize: "18px" }}>
                  {_.leads}
                </span>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
      <div>
        <Grid
          container
          direction="column"
          style={{ width: "97%", marginLeft: "18px", marginTop: "10px" }}
          justifyContent="center"
          boxShadow={"0px 0px 4px #0000001D"}
        >
          <Grid item>
            <Grid container justifyContent={"flex-start"} paddingLeft="16px">
              <Grid item>
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "#663595",
                  }}
                >
                  Select Invoices
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item paddingLeft="8px">
            <Grid
              container
              spacing={1}
              style={{ padding: "12px", alignItems: "center" }}
            >
              <Grid item xs="1">
                <TextField
                  size="small"
                  id="split-request-select"
                  select
                  disabled={reqselect && splitnum !== 0 ? true : false}
                  value={splitnum}
                  fullWidth
                  onChange={handleSplitSelect}
                >
                  {reqSelect.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      onClick={() => localStorage.removeItem("dates")}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs="11" style={{ zIndex: "9" }}>
                <Select
                  styles={colorStyles}
                  id="split-request"
                  isMulti
                  options={options}
                  isSearchable
                  isOptionDisabled={() => splitreq?.value?.length >= splitnum}
                  isDisabled={
                    splitnum <= 0 || (reqselect && splitnum !== 0)
                      ? true
                      : false
                  }
                  onChange={handleSplitReqChange}
                />
              </Grid>
              {/* <Grid item xs="1">
                <Button
                  style={{
                    textTransform: "unset",
                    background: reqselect && splitnum !== 0 ? "" : "#673695",
                  }}
                  variant="contained"
                  disabled={reqselect && splitnum !== 0 ? true : false}
                  onClick={handleInvDrop}
                >
                  Done
                </Button>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </div>

     

      <div style={{ padding: "13px", marginTop: "18px" }}>
        <Card>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              Invoice Details
            </Typography>
            <Grid container spacing={2}>
              {/* <Grid item xs={6}>
                <div className="table" style={{ marginTop: "21px" }}>
                  <Card style={{ height: "390px", overflowY: "scroll" }}>
                    <CardContent>
                      <DataTable
                        columns={columns}
                        data={dateData}
                        defaultSortFieldId={1}
                        //   pagination
                        //   paginationPerPage={7}
                        theme="solarized"
                      />
                    </CardContent>
                  </Card>
                </div>
              </Grid> */}
              <Grid item xs={12}>
                <div className="table" style={{ marginTop: "21px" }}>
                  {/* <Card style={{ height: "390px", overflowY: "scroll" }}>
                    <CardContent> */}
                  {/* <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    Request Details123
                  </Typography> */}
                  {
                    <Grid
                      container
                      direction="column"
                      justifyContent={"space-around"}
                    >
                      <Grid item>
                        <Grid
                          container
                          spacing={2}
                          directon="column"
                          sx={{ paddingRight: "13px" }}
                        >
                          {splitreq?.value?.map((item, i) => (
                            <>
                              <Grid item xs={4}>
                                <Typography
                                  sx={{ fs: "10px", textAlign: "start" }}
                                >
                                  {item.label}
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <GetDataData
                                  data={item.label.split("-")[0]}
                                  minDate1={mainMinDate}
                                  maxDate1={mainMaxDate}
                                  checkdata={dateData}
                                  value={otData[i]}
                                  dis={!changeButton}
                                />
                              </Grid>
                            </>
                          ))}
                        </Grid>
                      </Grid>
                      {/* <Grid
                      item
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {
                        <>
                          <Button variant="contained" onClick={handleDtData}>
                            testing
                          </Button>{" "}
                          &nbsp;&nbsp;
                          <Button variant="contained">Clear dates</Button>
                        </>
                      }
                    </Grid> */}
                    </Grid>
                  }
                  {/* </CardContent>
                  </Card> */}
                </div>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            {/* {console.log(reqselect,reqSelect,splitreq,dateLength,'test')} */}
            <Button variant='contained' style={{background:'#663595'}} onClick={()=>window.location.reload()}>
             clearDates
            </Button>
            <Button
              onClick={handleSplitSubmit}
              variant="contained"
              // style={{ background: !changeButton?"":  "#673695",color:!changeButton?'#fff':"#00" }}
              style={{background:'#663595'}}
              // disabled={(splitreq?.value?.length != JSON.parse(localStorage.getItem('dates'))?.length) ? true: false}
            >
              {changeButton ? "Submit Request" : "Confirm Date"}
            </Button>

           
          </CardActions>
        </Card>
      </div>
      <Snackbar open={openalert} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          success!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openalert2}
        autoHideDuration={4000}
        onClose={handleClose2}
      >
        <Alert onClose={handleClose2} severity="success" sx={{ width: "100%" }}>
          successfully submitted !
        </Alert>
      </Snackbar>
      <Snackbar
        open={openalert3}
        autoHideDuration={4000}
        onClose={handleClose3}
      >
        <Alert onClose={handleClose3} severity="warning" sx={{ width: "100%" }}>
          Missing Leads!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openalert4}
        autoHideDuration={2000}
        onClose={handleClose4}
      >
        <Alert onClose={handleClose3} severity="warning" sx={{ width: "100%" }}>
          Please select all the dates!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SplitRequest;
