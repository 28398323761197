const login_number = "https://farming-backend.taskmo.in/inv/getotp";
const otp_number = "https://farming-backend.taskmo.in/inv/verifyotp";
const invoiceList = "https://farming-backend.taskmo.in/invtoken/invoiceList";
const invoiceDetails =
  "https://farming-backend.taskmo.in/invtoken/invoiceDetails";
const fseDetails = "https://farming-backend.taskmo.in/invtoken/fsedetails";
const fseDetailsNoFo =
  "https://farming-backend.taskmo.in/invtoken/fsedetails_nonfo";
const leadDetails = "https://farming-backend.taskmo.in/invtoken/leadDetails";
const fseStats = "https://farming-backend.taskmo.in/invtoken/fseStats";
const extract_token = "https://farming-backend.taskmo.in/inv/extract_token";
const search_fse = "https://farming-backend.taskmo.in/invtoken/serach_fse";
const upload_agreement =
  "https://farming-backend.taskmo.in/invtoken/upload_agreement";
const recon_invoice =
  "https://farming-backend.taskmo.in/invtoken/recon_invoice";
const createPaymentreq =
  "https://farming-backend.taskmo.in/invtoken/createPaymentreq";
const getpaymentRequest =
  "https://farming-backend.taskmo.in/invtoken/getpaymentRequest";
const getAllpaymentRequest =
  "https://farming-backend.taskmo.in/invtoken/getAllpaymentRequest";
const upload_lead_report =
  "https://farming-backend.taskmo.in/invtoken/upload_lead_report";
const upload_payout_report =
  "https://farming-backend.taskmo.in/invtoken/upload_payout_report";

const payout_report =
  "https://farming-backend.taskmo.in/invtoken/download/payout_report";
const update_billing_date =
  "https://farming-backend.taskmo.in/invtoken/update_billing_date";
const process_invoice =
  "https://farming-backend.taskmo.in/invtoken/process_invoice";
const update_payment_data =
  "https://farming-backend.taskmo.in/invtoken/update_payment_data";

const client_details =
  "https://farming-backend.taskmo.in/invtoken/client_details";
const sow_rep = "https://farming-backend.taskmo.in/invtoken/download/sow_rep";
const pay_rep = "https://farming-backend.taskmo.in/invtoken/download/pay_rep";
const process_request =
  "https://farming-backend.taskmo.in/invtoken/process_request";
const total_rep = "https://farming-backend.taskmo.in/invtoken/download";

const createClient = "https://farming-backend.taskmo.in/invtoken/createClient";
const getAgreement = "https://farming-backend.taskmo.in/invtoken/getAgreement";
const getClient = "https://farming-backend.taskmo.in/invtoken/getClient";
const getEinvoice = "https://farming-backend.taskmo.in/invtoken/getEinvoice";
const user_info = "https://farming-backend.taskmo.in/invtoken/user_info";
const createInvoice =
  "https://farming-backend.taskmo.in/invtoken/createInvoice";
const update_invoice =
  "https://farming-backend.taskmo.in/invtoken/update_invoice";

const check_user = "https://farming-backend.taskmo.in/invtoken/check_user";
const delete_description =
  "https://farming-backend.taskmo.in/invtoken/delete_description";
const update_description =
  "https://farming-backend.taskmo.in/invtoken/update_description";
const add_description =
  "https://farming-backend.taskmo.in/invtoken/add_description";
const inv_description =
  "https://farming-backend.taskmo.in/invtoken/inv_description";
const upload_profile_photo =
  "https://farming-backend.taskmo.in/invtoken/upload_profile_photo";

const getpaymentRequestOps =
  "https://farming-backend.taskmo.in/invtoken/getpaymentRequestOps";

const process_payment =
  "https://farming-backend.taskmo.in/invtoken/process_payment";

  const update_client = "https://farming-backend.taskmo.in/invtoken/update_client"

  const get_sow = "https://farming-backend.taskmo.in/invtoken/getSows"

  const getInvoices = "https://farming-backend.taskmo.in/invtoken/getInv"

  const getSplitDate = "https://farming-backend.taskmo.in/invtoken/splitdate"

 const bulkaddpayreq = "https://farming-backend.taskmo.in/invtoken/bulkaddpayreq"

 const movetoInvoice = "https://farming-backend.taskmo.in/invtoken/movetoInvoice"

 const getInvtomove = "https://farming-backend.taskmo.in/invtoken/getInvtomove"

export {
  login_number,
  otp_number,
  invoiceList,
  invoiceDetails,
  fseDetails,
  leadDetails,
  fseStats,
  extract_token,
  search_fse,
  createPaymentreq,
  getpaymentRequest,
  upload_lead_report,
  upload_payout_report,
  fseDetailsNoFo,
  payout_report,
  update_billing_date,
  process_invoice,
  update_payment_data,
  client_details,
  sow_rep,
  createClient,
  recon_invoice,
  upload_agreement,
  getAgreement,
  getClient,
  createInvoice,
  update_invoice,
  check_user,
  total_rep,
  upload_profile_photo,
  user_info,
  inv_description,
  add_description,
  delete_description,
  update_description,
  getEinvoice,
  getpaymentRequestOps,
  process_payment,
  process_request,
  getAllpaymentRequest,
  update_client,
  pay_rep,
  get_sow,
  getInvoices,
  getSplitDate,
  bulkaddpayreq,
  movetoInvoice,
  getInvtomove
};
