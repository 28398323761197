import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import Stack from "@mui/material/Stack";
import axios from "axios";
import React, { useState } from "react";
import { dataToken } from "../../Config/token";
import { add_description, update_invoice } from "../../utils";
import Select from "react-select";
import Swal from "sweetalert2";
import "../css/Invedit.css";
import Desctable from "../Others/Desctable";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CircularProgress from "@mui/material/CircularProgress";
import FeatherIcon from "feather-icons-react";
const Invoice2022Modal = ({
  openEditInvoice,
  closeEditInvoice,
  Editdata,
  clientData,
}) => {
  const [startdate, setStartDate] = React.useState(
    settingDate(Editdata.start_date)
  );
  console.log(Editdata, "checking");
  const [podate, setPoDate] = React.useState(settingDate(Editdata.po_date));
  const [dueDate, setdueDate] = React.useState(settingDate(Editdata.due_date));
  const [enddate, setEndDate] = React.useState(settingDate(Editdata.end_date));
  const [dataEdit, setDataEdit] = React.useState({});
  const [addDesc, setAdddesc] = React.useState({});
  const [add, setAdd] = React.useState(false);
  const [changedue, setChangedue] = useState(false);
  const [changepo, setChangepo] = React.useState(false);
  const [changestart, setChangeStart] = React.useState(false);
  const [changeend, setChangeEnd] = React.useState(false);

  function settingDate(date) {
    let changeDate = date && date.split("-");
    changeDate = [changeDate[1], changeDate[0], changeDate[2]].join("-");
    return changeDate;
  }
  let defaultclientData;
  clientData.forEach((item, index) => {
    if (item.value == Editdata.clientid) {
      defaultclientData = index;
    }
  });

  const handleChangeinvoiceEdit = (e) => {
    if (e.label) {
    } else {
      const { name, value } = e.target;
      setDataEdit({ ...dataEdit, [name]: value });
    }
  };
  const handleChangeinvoiceEditclientData = (e) => {
    setDataEdit({ ...dataEdit, user_id: e.value });
  };

  const handleSubmitEdit = () => {
    dataEdit.invoice_id = Editdata.invoice_id;

    if (changestart) dataEdit.start_date = startdate;
    if (changeend) dataEdit.end_date = enddate;
    if (changepo) dataEdit.po_date = podate;
    if (changedue) dataEdit.due_date = dueDate;
    axios
      .post(update_invoice, dataEdit, dataToken)
      .then((res) => {
        closeEditInvoice();

        Swal.fire({
          icon: "success",
          title: "updated",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        closeEditInvoice();
        console.log((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops!",
          });
        });
      })
      .finally(() => {
        setChangeEnd(false);
        setChangeStart(false);
        setChangepo(false);
      });
  };
  const handleChangeAddDesc = (e) => {
    const { name, value } = e.target;
    setAdddesc({ ...addDesc, [name]: value });
  };
  const handleSubmitAdd = () => {
    addDesc.inv_id = Editdata.invoice_id;
    addDesc.amount = addDesc.cost * addDesc.quantity;
    addDesc.hsn = "998397";

    let compulsary = Object.keys(addDesc).includes(
      "cost",
      "description",
      "quantity"
    );
    if (compulsary) {
      axios
        .post(add_description, addDesc, dataToken)
        .then((res) => {
          setAdd(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChangeinvoiceEditpoDate = (newValue) => {
    const date = Number(newValue.$D) < 10 ? `0${newValue.$D}` : newValue.$D;
    const dateTime2 = `${newValue.$y}-${newValue.$M + 1}-${date}`;
    setPoDate(dateTime2);
    setChangepo(true);
  };

  const handleChangeinvoiceEditdueDate = (newValue) => {
    const date = Number(newValue.$D) < 10 ? `0${newValue.$D}` : newValue.$D;
    const dateTime2 = `${newValue.$y}-${newValue.$M + 1}-${date}`;
    setdueDate(dateTime2);
    setChangedue(true);
  };

  const handleChangeinvoiceEditstartDate = (newValue) => {
    const date = Number(newValue.$D) < 10 ? `0${newValue.$D}` : newValue.$D;
    const dateTime2 = `${newValue.$y}-${newValue.$M + 1}-${date}`;
    setStartDate(dateTime2);
    setChangeStart(true);
  };

  const handleChangeinvoiceEditendDate = (newValue) => {
    const date = Number(newValue.$D) < 10 ? `0${newValue.$D}` : newValue.$D;
    const dateTime2 = `${newValue.$y}-${newValue.$M + 1}-${date}`;
    setEndDate(dateTime2);
    setChangeEnd(true);
  };

  const options = clientData;
  const colorStyles = {
    control: (styles) => ({ ...styles, height: "55px" }),
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={openEditInvoice}
        onClose={closeEditInvoice}
        scroll={"paper"}
        style={{ maxHeight: "700px" }}
      >
        <DialogTitle style={{ color: "#663595" }}>Edit Invoice</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <label id="updateclientid">Update Client Id</label>
              <Select
                defaultValue={options[Number(defaultclientData)]}
                styles={colorStyles}
                id="updateclientid"
                options={options}
                isSearchable
                onChange={handleChangeinvoiceEditclientData}
              />
            </Grid>
            <Grid item md={6}>
              <label id="e_invoice_no">E-Invoice Number</label>
              <TextField
                defaultValue={Editdata.e_invoice_no}
                fullWidth
                id="e_invoice_no"
                type="text"
                name="e_invoice_no"
                variant="outlined"
                onChange={handleChangeinvoiceEdit}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={4}>
              <label id="billing_start_date">Billing Start Date</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3} style={{ marginTop: "10px" }}>
                  <DesktopDatePicker
                    id="billing_start_date"
                    inputFormat="DD/MM/YYYY"
                    name="start_date"
                    value={startdate}
                    onChange={handleChangeinvoiceEditstartDate}
                    renderInput={(params) => (
                      <TextField variant="outlined" {...params} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>

            <Grid item md={4}>
              <label id="billing_end_date">Billing End Date</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3} style={{ marginTop: "10px" }}>
                  <DesktopDatePicker
                    id="billing_end_date"
                    variant="outlined"
                    // label="Start Date"
                    inputFormat="DD/MM/YYYY"
                    value={enddate}
                    // value={Editdata.end_date}
                    onChange={handleChangeinvoiceEditendDate}
                    // onChange={handleChangeinvoiceEdit}
                    // onChange={handleChangeinvoiceEdit}
                    renderInput={(params) => (
                      <TextField variant="outlined" {...params} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item md={4}>
              <div className="d-flex flex-column mt-2">
                <label id="e_invoice_no">TDS Per</label>
                <select
                  aria-label="Default select example"
                  onChange={handleChangeinvoiceEdit}
                  className={"drop_css_2"}
                  name="tds_per"
                  required
                  defaultValue={Editdata.tds_per}
                >
                  <option selected disabled>
                    Select
                  </option>
                  <option value={"2"}>2</option>
                  <option value={"5"}>5</option>
                  <option value={"10"}>10</option>
                </select>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <label id="po_no">PO Number</label>
              <TextField
                defaultValue={Editdata.po_no}
                fullWidth
                id="po_no"
                type="text"
                name="po_no"
                variant="outlined"
                onChange={handleChangeinvoiceEdit}
              />
            </Grid>
            <Grid item md={4}>
              <label id="po_date">PO DATE</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <DesktopDatePicker
                    id="po_date"
                    // label="Start Date"
                    inputFormat="DD/MM/YYYY"
                    value={podate}
                    name="po_date"
                    onChange={handleChangeinvoiceEditpoDate}
                    renderInput={(params) => (
                      <TextField variant="outlined" {...params} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item md={4}>
              <label id="po_date">DUE DATE</label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={3}>
                  <DesktopDatePicker
                    id="po_date"
                    // label="Start Date"
                    inputFormat="DD/MM/YYYY"
                    value={dueDate}
                    name="due_date"
                    onChange={handleChangeinvoiceEditdueDate}
                    renderInput={(params) => (
                      <TextField variant="outlined" {...params} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Desctable Editdata={Editdata} handleSubmitAdd={handleSubmitAdd} />
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Button
                style={{
                  background: "#663595",
                  marginTop: "20px",
                  marginBottom: "20px",
                  color: "#fff",
                }}
                onClick={() => {
                  setAdd(!add);
                }}
              >
                Add Description
              </Button>
            </Grid>
          </Grid>
          {add == true ? (
            <Grid container spacing={2}>
              <Grid item md={8}>
                <label id="desc">Description</label>
                <TextField
                  fullWidth
                  name="description"
                  id="desc"
                  type="text"
                  variant="outlined"
                  onChange={handleChangeAddDesc}
                />
              </Grid>
              <Grid item md={4}>
                <label id="hsn">HSN</label>
                <TextField
                  fullWidth
                  defaultValue={"998397"}
                  name="hsn"
                  id="hsn"
                  type="text"
                  variant="outlined"
                  disabled
                  onChange={handleChangeAddDesc}
                />
              </Grid>
              <Grid item md={3}>
                <label id="quantity">Quantity</label>
                <TextField
                  fullWidth
                  name="quantity"
                  id="quantity"
                  type="text"
                  variant="outlined"
                  onChange={handleChangeAddDesc}
                />
              </Grid>
              <Grid item md={3}>
                <label id="cost">Cost</label>
                <TextField
                  fullWidth
                  name="cost"
                  id="cost"
                  type="text"
                  variant="outlined"
                  onChange={handleChangeAddDesc}
                />
              </Grid>
              <Grid item md={4}>
                <label id="amount">Amount</label>
                <TextField
                  fullWidth
                  name="amount"
                  id="amount"
                  value={addDesc.cost * addDesc.quantity}
                  type="text"
                  variant="outlined"
                  onChange={handleChangeAddDesc}
                  disabled
                />
              </Grid>
              <Grid item md={2}>
                <Button
                  style={{
                    background: "#663595",
                    float: "right",
                    marginTop: "50px",
                    color: "#fff",
                    marginRight: "10px",
                  }}
                  onClick={handleSubmitAdd}
                >
                  Add Description
                </Button>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </DialogContent>
        <div className="pb-3 mr-3">
          <Button
            style={{
              background: "#663595",
              float: "right",
              marginTop: "20px",
              color: "#fff",
            }}
            onClick={handleSubmitEdit}
          >
            Update
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default Invoice2022Modal;
