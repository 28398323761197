import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { FinanceContext } from "../../App";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import "./Nav.css";
import Zoom from "@mui/material/Zoom";
import Modal from "react-bootstrap/Modal";
import FseSearch from "../pages/FseSearch";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
function Nav() {
  const [context, setContext] = useContext(FinanceContext);
  const [show, setShow] = React.useState(false);
  return (
    <div className="nav_head">
      <div className="d-flex justify-content-between icon_placer">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "95%",
          }}
        >
          <div
            className="threeline"
            onClick={() => {
              context.threeLine = !context.threeLine;
              setContext({ ...context });
            }}
          >
            <img
              src={window.location.origin + "/images/threeline.svg"}
              alt="ott"
            />
          </div>

          <div className="threeline" onClick={() => {}}>
            <LightTooltip TransitionComponent={Zoom} title="Search FSE">
              <div
                onClick={() => setShow(true)}
                className="profile"
                style={{
                  cursor: "pointer",
                  background: "black",
                  color: "#fff",
                  fontWeight: 600,
                  padding: "10px",
                  height: "40px",
                  width: "40px",
                  fontSize: "12px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                FSE
              </div>
            </LightTooltip>
          </div>
        </div>

        <div className="d-flex">
          {/* <div className="icons_end">
            <img
              src={window.location.origin + "/images/search.svg"}
              alt="ott"
            />
          </div>
          <div className="icons_end">
            <img
              src={window.location.origin + "/images/notification.svg"}
              alt="ott"
            />
          </div>
          <div className="icons_end">
            <img
              src={window.location.origin + "/images/setting.svg"}
              alt="ott"
            />
          </div> */}
          {/* <div className="profile">
            {localStorage.getItem("finance_email").split("")[0]}
          </div> */}
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)}>
        <FseSearch setShow={setShow} />
      </Modal>
    </div>
  );
}
export default Nav;
