import axios from "axios";
import React, { useState } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { dataToken } from "../../Config/token";
import { update_payment_data } from "../../utils";
function UpdateDetails({ dumpData }) {
  const {
    p_cpl,
    leads,
    type,
    billable_leads,
    billing_amt,
    start_date,
    end_date,
    sow_id,
    payment_date,
  } = dumpData;
  const [data, setData] = useState({
    leads,
    p_cpl,
    type,
    billable_leads,
    billing_amt,
    start_date,
    end_date,
    sow_id,
    payment_date,
  });
  const handlesubmit = (event) => {
    event.preventDefault();
    if (Object.keys(data).length != 0) {
      data["req_id"] = dumpData.req_id;
      data["cpl"] = data.p_cpl;
      delete data.p_cpl;
      axios
        .post(update_payment_data, data, dataToken)
        .then((res) => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `success`,
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: `${err.response.data.message}`,
          });
        });
    }
  };
  return (
    <div>
      <span className="add_heading"> Update Details</span>
      <Form onSubmit={handlesubmit}>
        <Row className="mt-5">
          <Col sx={12} md={6}>
            <Form.Group className="mb-4 drop_flex" controlId="ControlInput">
              <Form.Label>Payout Type</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  data.type = e.target.value;
                  setData({ ...data });
                }}
                className={"drop_css"}
                required
              >
                <option selected disabled>
                  {type}
                </option>
                <option value={"auto_payment"}>Auto Payment</option>
                <option value={"manual_payment"}>Manual Payment</option>
                <option value={"other_expenses"}>Other Expenses</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>SOW ID</Form.Label>
              <Form.Control
                type="text"
                defaultValue={sow_id}
                onChange={(e) => {
                  data.sow_id = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>CPL</Form.Label>
              <Form.Control
                type="text"
                defaultValue={p_cpl}
                onChange={(e) => {
                  data.p_cpl = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Leads</Form.Label>
              <Form.Control
                type="text"
                defaultValue={leads}
                onChange={(e) => {
                  data.leads = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Billable Leads</Form.Label>
              <Form.Control
                type="text"
                defaultValue={billable_leads}
                onChange={(e) => {
                  data.billable_leads = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Billable Amount</Form.Label>
              <Form.Control
                type="text"
                defaultValue={billing_amt}
                onChange={(e) => {
                  data.billing_amt = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sx={12} md={4}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                defaultValue={start_date}
                onChange={(e) => {
                  data.start_date = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={4}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                defaultValue={end_date}
                onChange={(e) => {
                  data.end_date = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={4}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Payment Processing Date</Form.Label>
              <Form.Control
                type="date"
                defaultValue={payment_date}
                onChange={(e) => {
                  data.payment_date = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end">
          <Button
            className={"button_submit btn_pos"}
            type="submit"
            style={{ marginRight: "50px" }}
          >
            UPDATE
          </Button>
        </Row>
      </Form>
    </div>
  );
}

export default UpdateDetails;
