import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  delete_description,
  inv_description,
  update_description,
} from "../../utils";
import { dataToken } from "../../Config/token";
import axios from "axios";
import { TextField } from "@material-ui/core";
import FeatherIcon from "feather-icons-react";
import { Textarea } from "@mui/joy";
import Swal from "sweetalert2";

export default function Desctable({ Editdata, handleSubmitAdd }) {
  const [desc, setDesc] = React.useState([]);
  const [editModalData, setEditModalData] = React.useState(false);
  const [desIdCheck, setDescIdCheck] = React.useState("");
  const [confirmDelete, setConfirmdelete] = React.useState(false);
  const [editedData, setEditedData] = React.useState({});
  React.useEffect(() => {
    const postData = {
      inv_id: Editdata.invoice_id,
    };
    axios
      .post(inv_description, postData, dataToken)
      .then((res) => {
        setDesc(res.data.desc);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [Editdata, handleSubmitAdd, confirmDelete]);
  const handleClickOpen = (row) => {
    Swal.fire({
      title: `Are you sure you want to delete "${row.description}"?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: " #d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        container: "swal-z-index",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteData = {
          desc_id: row.desc_id,
          inv_id: Editdata.invoice_id,
        };
       
        axios
          .post(delete_description, deleteData, dataToken)
          .then((res) => {
            setConfirmdelete(!confirmDelete);
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
              customClass: {
                container: "swal-z-index",
              },
            });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    });
  };

  const TAX_RATE = 0.18;

  function ccyFormat(num) {
    return `${num.toFixed(2)}`;
  }

  function subtotal(items) {
    return items.map(({ amount }) => amount).reduce((sum, i) => sum + i, 0);
  }
  const invoiceSubtotal = subtotal(desc);
  const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  const invoiceTotal = invoiceTaxes + invoiceSubtotal;

  const handleChangeEditData = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
  };

  const descUpdate = (row) => {
    editedData.desc_id = row.desc_id;
    editedData.inv_id = Editdata.invoice_id;
    if (editedData.quantity) {
      editedData.amount = editedData.quantity * row.cost;
    }
    if (editedData.cost) {
      editedData.amount = editedData.cost * row.quantity;
    }
    if (editedData.cost && editedData.quantity) {
      editedData.amount = editedData.cost * editedData.quantity;
    }
    

    axios
      .post(update_description, editedData, dataToken)
      .then((res) => {
        setConfirmdelete(!confirmDelete);
        Swal.fire({
          title: "Updated!",
          text: "Your Description has been Edited.",
          icon: "success",
          customClass: {
            container: "swal-z-index",
          },
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={3}>
              Details
            </TableCell>
            <TableCell align="right" colSpan={3}>
              Price
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Edit</TableCell>
            <TableCell>Delete</TableCell>
            <TableCell>Desc</TableCell>
            <TableCell align="right">Qty.</TableCell>
            <TableCell align="right">Cost</TableCell>
            <TableCell align="right">Sum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {desc?.map((row) => (
            <TableRow key={row.desc_id}>
              <TableCell>
                {(desIdCheck.desc_id == "" ||
                  desIdCheck.desc_id !== row.desc_id ||
                  !editModalData) && (
                  <FeatherIcon
                    icon="edit"
                    style={{ color: "#663595", cursor: "pointer" }}
                    onClick={() => {
                      setEditModalData(true);
                      setDescIdCheck(row);
                     
                    }}
                  />
                )}
                {editModalData && desIdCheck.desc_id == row.desc_id && (
                  <FeatherIcon
                    className={"text-success"}
                    icon="check-square"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      descUpdate(row);
                      setEditModalData(false);
                    }}
                  />
                )}
              </TableCell>
              <TableCell>
                <FeatherIcon
                  className={"text-danger"}
                  icon="trash-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleClickOpen(row);
                  }}
                />
              </TableCell>

              {!editModalData || desIdCheck.desc_id !== row.desc_id ? (
                <TableCell>{row.description}</TableCell>
              ) : (
                <TableCell>
                  <Textarea
                    variant="outlined"
                    defaultValue={row.description}
                    name="description"
                    onChange={handleChangeEditData}
                    fullWidth
                  />
                </TableCell>
              )}
              {!editModalData || desIdCheck.desc_id !== row.desc_id ? (
                <TableCell align="right">{row.quantity}</TableCell>
              ) : (
                <TableCell>
                  <TextField
                    variant="outlined"
                    name="quantity"
                    onChange={handleChangeEditData}
                    defaultValue={row.quantity}
                  />
                </TableCell>
              )}
              {!editModalData || desIdCheck.desc_id !== row.desc_id ? (
                <TableCell align="right">{row.cost}</TableCell>
              ) : (
                <TableCell>
                  <TextField
                    variant="outlined"
                    name="cost"
                    onChange={handleChangeEditData}
                    defaultValue={row.cost}
                  />
                </TableCell>
              )}
              {!editModalData || desIdCheck.desc_id !== row.desc_id ? (
                <TableCell align="right">{row.amount}</TableCell>
              ) : (
                <TableCell>
                  <TextField
                    variant="outlined"
                    defaultValue={row.amount}
                    name="amount"
                    onChange={handleChangeEditData}
                    disabled
                    value={
                      editedData.quantity && editedData.cost
                        ? editedData.quantity * editedData.cost
                        : editedData.quantity
                        ? editedData.quantity * row.cost
                        : editedData.cost
                        ? editedData.cost * row.quantity
                        : row.amount
                    }
                  />
                </TableCell>
              )}
            </TableRow>
          ))}

          <TableRow>
            <TableCell rowSpan={3} colSpan={3} />
            <TableCell colSpan={2}>Subtotal</TableCell>
            <TableCell align="right">{invoiceSubtotal}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={1}>Tax</TableCell>
            <TableCell align="right">{`${(TAX_RATE * 100).toFixed(
              0
            )} %`}</TableCell>
            <TableCell align="right">{ccyFormat(invoiceTaxes)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>Total</TableCell>
            <TableCell align="right">{ccyFormat(invoiceTotal)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
