import React, { useState, useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import "../../../src/components/pages/FseSearch.css";
import { sow_rep, pay_rep, get_sow } from "../../utils";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import axios from "axios";
import { dataToken } from "../../Config/token";

const LgSearchModal = ({ show2, setShow2 }) => {
  const [data, setData] = React.useState({});
  const [datechange, setDatechange] = React.useState({
    created: false,
  });
  const [status, setStatus] = useState("green");
  const [sowdata, setSowdata] = useState([]);

  const token = localStorage.getItem("token");
  const [clear, setClear] = React.useState("");

  useEffect(() => {
    axios
      .get(get_sow, dataToken)
      .then((res) => console.log(setSowdata(res.data.sows)))
      .catch((err) => console.log(err));
  }, []);

  const options = sowdata;
  const colorStyles = {
    control: (styles) => ({ ...styles, height: "55px" }),
  };
  const handleChange = (event) => {
    setDatechange({
      ...datechange,
      [event.target.name]: event.target.checked,
    });
  };
  const leads = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "green",
      label: "Green",
    },
    {
      value: "green_yellow",
      label: "Green Yellow",
    },
    {
      value: "rejected",
      label: "rejected",
    },
  ];

  return (
    <Modal
      style={{ marginTop: "100px" }}
      show={show2}
      onHide={() => setShow2(false)}
    >
      <div className="total_fse_search">
        <div className="d-flex justify-content-between">
          <h4 className="mb-4 leadgenerator">Lead Generator</h4>
          <div style={{ width: "50%" }}>
            <TextField
              id="select-lead-generator"
              defaultValue={"green"}
              select
              size={"small"}
              style={{ width: "50%", marginRight: "10px" }}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              {leads.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <FormControlLabel
              control={
                <Switch
                  checked={datechange.created}
                  onChange={handleChange}
                  name="created"
                />
              }
              label="Created Date"
            />
          </div>
        </div>
        <Row>
          <Col sx={12} md={12}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <label id="sowid">Sow Id</label>
              <Select
                styles={colorStyles}
                id="sowid"
                options={options}
                isSearchable
                isClearable={data.sow_id}
                onChange={(e) => {
                  data.sow_id = e ? e.value : null
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => {
                  data.start_date = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>

          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => {
                  data.end_date = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col
            style={{ display: "flex", justifyContent: "flex-end", gap: "18px" }}
          >
            {
              <a
                href={`${pay_rep}?token=${token}&sow_id=${
                  data.sow_id
                }&start_date=${data.start_date}&end_date=${
                  data.end_date
                }&type=${datechange.created ? "other" : "qc"}&status=${status}`}
                target="_blank"
              >
                <button
                  className="buttongenerate"
                  style={{
                    opacity:
                      Object.values(data).length < 3 ||
                      Object.values(data).includes("")
                        ? "0.8"
                        : null,
                  }}
                  disabled={
                    Object.values(data).length < 3 ||
                    Object.values(data).includes("")
                  }
                >
                  Payout Report
                </button>
              </a>
            }
            {
              <a
                href={`${sow_rep}?token=${token}&sow_id=${
                  data.sow_id
                }&start_date=${data.start_date}&end_date=${
                  data.end_date
                }&type=${datechange.created ? "other" : "qc"}&status=${status}`}
                target="_blank"
              >
                <button
                  className="buttongenerate"
                  style={{
                    opacity:
                      Object.values(data).length < 3 ||
                      Object.values(data).includes("")
                        ? "0.8"
                        : null,
                  }}
                  disabled={
                    Object.values(data).length < 3 ||
                    Object.values(data).includes("")
                  }
                >
                  Lead Report
                </button>
              </a>
            }
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default LgSearchModal;
