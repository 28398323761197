import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import CircularProgress from "@mui/material/CircularProgress";
import { dataToken, token, token_type } from "../../Config/token";
import {
  getAllpaymentRequest,
  payout_report,
  process_payment,
} from "../../utils";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FeatherIcon from "feather-icons-react";

import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
} from "@mui/material";
import { SevenKOutlined } from "@mui/icons-material";

createTheme("solarized", {
  text: {
    primary: "black",
    secondary: "#b2b2b2",
  },
  background: {
    default: "#fff",
  },
  boxshadow: {
    default: "5px 5px 30px #DEDEDEBF",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#f1f1f1",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

function Processreq() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [sowwId, setSowwId] = React.useState("");
  const [show3, setShow3] = React.useState(false);
  const [requId, setRequId] = React.useState("");
  const [type, setType] = React.useState("");
  const [leadData, setleadData] = useState({});
  const [updateboolProcess, setupdateBoolProcess] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [isError, setIsError] = React.useState(false);
  const [payment_ref, setPayment_ref] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [payment_stat, setPayment_stat] = React.useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePaymentChange = (e) => {
    setPayment_ref(e.target.value);
  };

  const onclickOpenSureD = (d) => {
    setSowwId(d.sow_id);
    setRequId(d.req_id);
    setType(d.payment_type);
    setShow3(true);
  };

  const handleCloseSureD = () => {
    setShow3(false);
  };
  useEffect(() => {
    const data = {
      status: payment_stat,
    };

    axios
      .post(getAllpaymentRequest, data, dataToken)
      .then((res) => {
        setleadData(res.data.totalData);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [updateboolProcess, payment_stat]);

  const processPaymentFunc = () => {
    handleCloseSureD();

    const data = {
      req_id: requId,
      sow_id: sowwId,
      type: type,
      payment_reference: payment_ref,
    };

    setPayment_ref(null);
    setIsLoading(true);
    axios
      .post(process_payment, data, dataToken)
      .then((res) => {
        window.location.reload();
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
        setupdateBoolProcess(!updateboolProcess);
      });
  };
  const columns = [
    {
      name: " DAys left ",
      selector: (d) => d.days_left,
      cell: (d) => (
        <div>
          {d.paid > 0 ? (
            <Chip label={`Paid`} color="success" />
          ) : d.days_left > 0 ? (
            <Chip label={`Due in ${d.days_left} Days`} color="success" />
          ) : d.days_left < 0 ? (
            <Chip
              label={`Overdue by ${Math.abs(d.days_left)} Days`}
              color="error"
            />
          ) : (
            <Chip label={`Not Updated`} color="warning" />
          )}
        </div>
      ),
      sortable: true,
      center: true,
      //   width: "165px",
    },
    {
      name: "ID",
      selector: (d) => d.req_id,
      cell: (d) => <div className="loanid">{d.req_id}</div>,
      sortable: true,
      center: true,
      //   width: "70px",
    },
    {
      name: "SOW ID",
      selector: (d) => d.sow_id,
      cell: (d) => (
        <div>
          <div className="loanid">{d.sow_id}</div>
        </div>
      ),
      sortable: true,
      center: true,
      //   width: "100px",
    },
    {
      name: " Date",
      selector: (d) => d.display_date,
      cell: (d) => <div className="date">{d.display_date}</div>,
      sortable: true,
      center: true,
      //   width: "165px",
    },

    {
      name: "Total Leads",
      selector: (d) => d.total_leads,
      //   width: "120px",
      cell: (d) => <div className="amt_table">{d.total_leads}</div>,
      sortable: true,
      center: true,
    },
    {
      name: "CPL",
      selector: (d) => d.cpl,
      //   width: "120px",
      cell: (d) => <div className="amt_table">{d.cpl}</div>,
      sortable: true,
      center: true,
    },
    {
      name: "Total",
      cell: (d) => <div className="amt_table">{d.total}</div>,
      sortable: true,
      center: true,
      //   width: "120px",
    },
    {
      name: "t_amt",
      selector: (d) => d.payment_type,
      cell: (d) => <div className="amt_table">{d.payment_type}</div>,
      sortable: true,
      center: true,
      //   width: "120px",
    },
    {
      name: "Download",
      selector: (d) => "download",
      cell: (d) => (
        <div className="d-flex ">
          <a
            href={`${payout_report}?token=${token}&req_id=${d.req_id}&payment_type=${d.payment_type}`}
          >
            <button className="btn-css-download">
              <FeatherIcon icon="download-cloud" size={24} />
            </button>
          </a>
        </div>
      ),
      sortable: true,
      center: true,
      width: "120px",
    },
    {
      name: "process payment",
      //   width: "100px",
      selector: (d) => d.paid,
      sortable: true,
      center: "true",
      cell: (d) => (
        <div>
          {isLoading ? (
            <>
              <CircularProgress />
            </>
          ) : (
            <div
              onClick={() => {
                d.count > 0 && d.paid == 0 && onclickOpenSureD(d);
              }}
              style={{ cursor: "pointer" }}
            >
              {d.count == 0 ? (
                <img
                  src={
                    window.location.origin + "/images/process_payment_grey.svg"
                  }
                />
              ) : d.count > 0 && d.paid == "0" ? (
                <img
                  src={
                    window.location.origin +
                    "/images/process_payment_purple.svg"
                  }
                />
              ) : (
                <img
                  src={
                    window.location.origin + "/images/process_payment_green.svg"
                  }
                />
              )}
            </div>
          )}
        </div>
      ),
    },
  ];
  const dataTable = {
    columns:
      token_type !== "admin" ? columns.splice(0, columns.length - 1) : columns,
    data: leadData?.req,
  };
  return (
    <div>
      <Button
        style={{
          position: "absolute",
          right: "28px",
          top: "171px",
          zIndex: "99",
        }}
        onClick={handleClick}
        variant="contained"
        startIcon={<FilterAltIcon />}
      >
        filter
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setPayment_stat("all");
          }}
        >
          All
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setPayment_stat("processed");
          }}
        >
          Processed
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setPayment_stat("notprocessed");
          }}
        >
          Not Processed
        </MenuItem>
      </Menu>
      <div className="dash_container" style={{ position: "relative" }}>
        <div className="table_data">
          <div className="card_table">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "12px",
              }}
            >
              <div
                style={{
                  marginBottom: "10px",

                  border: "1px solid #663595",
                  background: "#663595",
                  height: "40px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  padding: "5px",
                  width: "200px",
                }}
              >
                KL: &nbsp;{" "}
                <span style={{ fontWeight: "900", fontSize: "18px" }}>
                  {Math.floor(Number(leadData.kl))}
                </span>
              </div>
              <div
                style={{
                  marginBottom: "10px",

                  border: "1px solid #663595",
                  background: "#663595",
                  height: "40px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  padding: "5px",
                  width: "200px",
                }}
              >
                CF: &nbsp;{" "}
                <span style={{ fontWeight: "900", fontSize: "18px" }}>
                  {Math.floor(Number(leadData.cf))}
                </span>
              </div>
              <div
                style={{
                  marginBottom: "10px",

                  border: "1px solid #663595",
                  background: "#663595",
                  height: "40px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  padding: "5px",
                  width: "200px",
                }}
              >
                Total: &nbsp;{" "}
                <span style={{ fontWeight: "900", fontSize: "18px" }}>
                  {Math.floor(Number(leadData.total))}
                </span>
              </div>
            </div>
            <DataTableExtensions
              {...dataTable}
              filterPlaceholder={`Search`}
              className="extension_option"
            >
              <DataTable
                columns={columns}
                data={leadData?.req}
                defaultSortFieldId={1}
                pagination
                paginationPerPage={5}
                theme="solarized"
              />
            </DataTableExtensions>
          </div>
        </div>
      </div>
      {show3 && (
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={show3}
          onClose={handleCloseSureD}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Process Payment"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to Process this payment.
              <div style={{ width: "100%", marginTop: "16px" }}>
                <label
                  htmlFor="payment"
                  style={{ fontWeight: "500px", color: "#000" }}
                >
                  Payment Reference
                </label>
                <TextField
                  fullWidth={true}
                  id="payment"
                  variant="outlined"
                  onChange={handlePaymentChange}
                />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={payment_ref ? false : true}
              autoFocus
              onClick={processPaymentFunc}
            >
              Yes
            </Button>
            <Button onClick={handleCloseSureD} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default Processreq;
