import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import "../../../src/components/pages/FseSearch.css";
import { token } from "../../Config/token";

import { sow_rep, total_rep } from "../../utils";

const RGDownlaodModal = ({ show3, setShow3 }) => {
  const [data, setData] = React.useState({});

  return (
    <Modal
      style={{ marginTop: "100px" }}
      show={show3}
      onHide={() => setShow3(false)}
    >
      <div className="total_fse_search">
        <h4 className="mb-4 leadgenerator">Report Generator</h4>
        <Row>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Select Type</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  data.type = e.target.value;
                  setData({ ...data });
                }}
                className={"drop_css"}
                style={{ width: "700px" }}
                required
              >
                <option selected disabled>
                  Select Payout Type
                </option>
                <option value={"payout_userwise"}>Payout Data</option>
                <option value={"other_expenses"}>Other Expenses</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => {
                  data.start_date = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>

          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => {
                  data.end_date = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            {
              <a
                href={`${total_rep}/${data.type}?token=${token}&start_date=${data.start_date}&end_date=${data.end_date}`}
                target="_blank"
              >
                <button
                  style={{
                    opacity:
                      Object.values(data).length < 3 ||
                      Object.values(data).includes("")
                        ? "0.8"
                        : null,
                  }}
                  disabled={
                    Object.values(data).length < 3 ||
                    Object.values(data).includes("")
                  }
                  className="buttongenerate"
                >
                  Generate
                </button>
              </a>
            }
            
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default RGDownlaodModal;
