import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import green from "@material-ui/core/colors/green";
import Moda from "@material-ui/core/Modal";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Box,
  Divider,
  Tooltip,
  tooltipClasses,
  Avatar,
  MenuItem,
  Menu,
  Badge,
  Grid,
} from "@mui/material";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import "./Nav.css";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Modal } from "react-bootstrap";
import FseSearch from "../pages/FseSearch";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import LgSearchModal from "../Modal/LgSearchModal";
import RGDownloadModal from "../Modal/RGDownloadModal";
import UVModal from "../Modal/UVModal";
import ProfileCard from "../Modal/ProfileCard";
import axios from "axios";
import { dataToken, fin_id } from "../../Config/token";
import { user_info } from "../../utils";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { orange } from "@mui/material/colors";
import { height } from "@mui/system";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {},
  customColor: {
    backgroundColor: green[500],
  },
  customHeight: {
    minHeight: 200,
  },
  toolbar: theme.mixins.toolbar,
}));

export const Nav2 = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [profileImage, setProfileImage] = React.useState(null);

  useEffect(() => {
    const postData = {
      fin_id: fin_id,
    };
    axios
      .post(user_info, postData, dataToken)
      .then((res) => {
        setProfileImage(res.data.user_data.profile_photo);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [profileImage]);

  const [showNestedItems, setShowNestedItems] = React.useState(false);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const handleClickOpen = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const open3 = Boolean(anchorEl3);
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const [show2, setShow2] = React.useState(false);
  const [show3, setShow3] = React.useState(false);
  const [show4, setShow4] = React.useState(false);
  const [show5, setShow5] = React.useState(false);
  const list = () => (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        <ListItem>
          <ListItemButton
            onClick={() => {
              handleClose2();
              handlesidebarclick(0);
            }}
          >
            <ListItemIcon>
              <img
                src={window.location.origin + "/images/logopurple.svg"}
                alt="login"
                className="logo_css_side"
              />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>

        <ListItem>
          <ListItemButton
            onClick={() => {
              //  handleClose2();
              handlesidebarclick(1);
              setShowNestedItems(!showNestedItems);
            }}
          >
            <ListItemIcon>
              <img
                src={window.location.origin + "/images/invoice.svg"}
                alt=""
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    gap: "20px",
                    marginLeft: "-25px",
                  }}
                >
                  <div>Invoices</div>
                  <div>
                    {showNestedItems ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </div>
                </div>
              }
            />
          </ListItemButton>
        </ListItem>

        {showNestedItems && (
          <ListItem style={{ paddingLeft: "40px" }}>
            <ListItemButton
              onClick={() => {
                handleClose2();
                handlesidebarclick(1);
              }}
            >
              <ListItemIcon>
                <LabelImportantIcon style={{ color: "#663595" }} />
              </ListItemIcon>
              <ListItemText primary={"2023-24"} />
            </ListItemButton>
          </ListItem>
        )}

        {showNestedItems && (
          <ListItem style={{ paddingLeft: "40px" }}>
            <ListItemButton
              onClick={() => {
                handleClose2();
                handlesidebarclick(2);
              }}
            >
              <ListItemIcon>
                <LabelImportantIcon style={{ color: "#663595" }} />
              </ListItemIcon>
              <ListItemText primary={"2022-23"} />
            </ListItemButton>
          </ListItem>
        )}

        {showNestedItems && (
          <ListItem style={{ paddingLeft: "40px" }}>
            <ListItemButton
              onClick={() => {
                handleClose2();
                handlesidebarclick(3);
              }}
            >
              <ListItemIcon>
                <LabelImportantIcon style={{ color: "#663595" }} />
              </ListItemIcon>
              <ListItemText primary={"2021-22"} />
            </ListItemButton>
          </ListItem>
        )}

        {showNestedItems && (
          <ListItem style={{ paddingLeft: "40px" }}>
            <ListItemButton
              onClick={() => {
                handleClose2();
                handlesidebarclick(4);
              }}
            >
              <ListItemIcon>
                <LabelImportantIcon style={{ color: "#663595" }} />
              </ListItemIcon>
              <ListItemText primary={"2020-21"} />
            </ListItemButton>
          </ListItem>
        )}

        <ListItem>
          <ListItemButton
            onClick={() => {
              handleClose2();
              handlesidebarclick(5);
            }}
          >
            <ListItemIcon>
              <img src={window.location.origin + "/images/client.svg"} alt="" />
            </ListItemIcon>
            <ListItemText primary={"Client"} />
          </ListItemButton>
        </ListItem>
        {/* <ListItem>
          <ListItemButton
            onClick={() => {
              handleClose2();
              handlesidebarclick(6);
            }}
          >
            <ListItemIcon>
              <img
                src={
                  window.location.origin + "/images/process_payment_icon.svg"
                }
                alt=""
              />
            </ListItemIcon>
            <ListItemText primary={"Payment Request"} />
          </ListItemButton>
        </ListItem> */}
        {/* <ListItem>
          <ListItemButton
            onClick={() => {
              handleClose2();
              handlesidebarclick(7);
            }}
          >
            <ListItemIcon>
              <img
                src={
                  window.location.origin + "/images/request_payment_icon.svg"
                }
                alt=""
              />
            </ListItemIcon>
            <ListItemText primary={"Process Request"} />
          </ListItemButton>
        </ListItem> */}
      </List>
      <Divider />
    </Box>
  );

  const handlesidebarclick = (i) => {
    if (i == 0) return "";
    if (i == 1) {
      navigate("/invoice2023");
      handleClose();
    }
    if (i == 2) {
      navigate("/invoice2022");
      handleClose();
    }
    if (i == 3) {
      navigate("/invoice2021");
      handleClose();
    }
    if (i == 4) {
      navigate("/invoice2020");
      handleClose();
    }
    if (i == 5) {
      navigate("/client");
    }
    if (i == 6) {
      navigate("/request");
    }
    if (i == 7) {
      navigate("/process_request");
    }
    if (i == 8) {
      navigate("/vendor_payout");
    }
  };
  const classes = useStyles();
  const handleLogout = () => {
    localStorage.removeItem("finance_email");
    localStorage.removeItem("token");
    localStorage.removeItem("type");
    navigate("/login");
    window.location.reload();
  };

  function getRandomColor() {
    var letters = "0123456789ABCDEF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.round(Math.random() * 15)];
    }
    return color;
  }

  return (
    <React.Fragment>
      <AppBar position="fixed" style={{ backgroundColor: "#673695" }}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleClickOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Finance Dashboard
          </Typography>
          <div
            style={{
              marginLeft: "auto",
              marginRight: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "32px",
            }}
          >
            {/* <Tooltip title=" User Validator">
              <IconButton onClick={() => setShow4(true)}>
                <Avatar sx={{ bgcolor: "black", fontSize: "12px" }}>UV</Avatar>
              </IconButton>
            </Tooltip>
            <Tooltip title=" Report Generator">
              <IconButton onClick={() => setShow3(true)}>
                <Avatar sx={{ bgcolor: "black", fontSize: "12px" }}>RG</Avatar>
              </IconButton>
            </Tooltip>
            <Tooltip title=" Lead Generator">
              <IconButton onClick={() => setShow2(true)}>
                <Avatar sx={{ bgcolor: "black", fontSize: "12px" }}>LG</Avatar>
              </IconButton>
            </Tooltip>
            <Tooltip title="Search FSE">
              <IconButton onClick={() => setShow(true)}>
                <Avatar sx={{ bgcolor: "black", fontSize: "12px" }}>FSE</Avatar>
              </IconButton>
            </Tooltip> */}
            {/* <IconButton color="inherit"></IconButton> */}
            {/* <Badge
              color="info"
              badgeContent={2}
              showZero
              sx={{ "& .MuiBadge-badge": { bgcolor: "green" } }}
              id="basic-button"
              aria-controls={open ? "basic-notification" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick3}
            >
              <NotificationsActiveRoundedIcon style={{ fontSize: "30px" }} />
            </Badge> */}
            <Menu
              id="basic-notification"
              anchorEl={anchorEl3}
              open={open3}
              onClose={handleClose3}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                "& .MuiPaper-root": {
                  maxWidth: { xs: "100%", sm: "30%" },
                  p: "12px 2px 0px 12px",
                  "& .MuiList-padding": {
                    paddingBottom: "0px",
                  },
                },
              }}
            >
              <div>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    bgcolor: "#fff",
                    pt: "8px !important",
                    pb: "8px !important",
                  }}
                >
                  <Grid
                    item
                    container
                    sm={2}
                    sx={{
                      p: "0px !important",
                      pr: "8px !important",
                      pl: "8px !important",
                    }}
                    // alignItems="center"
                    // justifyContent="center"
                  >
                    <Avatar
                      sx={{
                        bgcolor: getRandomColor(),
                        ml: "8px",
                      }}
                    >
                      N
                    </Avatar>
                  </Grid>
                  <Grid container sm={8} alignItems="center">
                    <Grid item container sm={10}>
                      <Box
                        display="flex"
                        width="100%"
                        mb="8px"
                        alignItems={"center"}
                      >
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            marginBottom: "0px",
                          }}
                        >
                          Navin
                        </p>
                        <Divider
                          orientation="vertical"
                          flexItem
                          variant="center"
                          textAlign="center"
                          style={{
                            margin: "0px 6px 0px 6px",
                            fontWeight: "500",
                            height: "19px",
                            borderRightWidth: 1.1,
                            // backgroundColor: "#000",
                          }}
                        />
                        <p
                          style={{
                            fontSize: "10px",
                            fontWeight: "300",
                            marginBottom: "0px",
                          }}
                        >
                          24 sept 22@10:30 am
                        </p>
                      </Box>
                      <div
                        style={{
                          width: "100%",
                          fontWeight: "300",
                          fontSize: "10px",
                          wordWrap: "break-word",
                        }}
                      >
                        hiiiiasdfghjzssdxcfgvhbdfgchvjbdfgchvjbdxfcghvjbdfghdfghjfcghvjbdfghjfgh
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    sm={2}
                    sx={{ p: "0px !important" }}
                    alignItems="flex-start"
                    justifyContent="flex-end"
                  >
                    <CancelRoundedIcon sx={{ mr: "12px" }} />
                  </Grid>
                  <Divider
                    style={{
                      borderStyle: "dotted",
                      width: "95%",
                      margin: "8px 0px 8px",
                      marginLeft: "14px",
                    }}
                  />
                </Grid>
              </div>

              <div>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    bgcolor: "#fff",
                    pt: "8px !important",
                    pb: "8px !important",
                  }}
                >
                  <Grid
                    item
                    container
                    sm={2}
                    sx={{
                      p: "0px !important",
                      pr: "8px !important",
                      pl: "8px !important",
                    }}
                    // alignItems="center"
                    // justifyContent="center"
                  >
                    <Avatar
                      sx={{
                        bgcolor: getRandomColor(),
                        ml: "8px",
                      }}
                    >
                      N
                    </Avatar>
                  </Grid>
                  <Grid container sm={8} alignItems="center">
                    <Grid item container sm={10}>
                      <Box
                        display="flex"
                        width="100%"
                        mb="8px"
                        alignItems={"center"}
                      >
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            marginBottom: "0px",
                          }}
                        >
                          Navin
                        </p>
                        <Divider
                          orientation="vertical"
                          flexItem
                          variant="center"
                          textAlign="center"
                          style={{
                            margin: "0px 6px 0px 6px",
                            fontWeight: "500",
                            height: "19px",
                            borderRightWidth: 1.1,
                            bordercolor: "black",
                          }}
                        />
                        <p
                          style={{
                            fontSize: "10px",
                            fontWeight: "300",
                            marginBottom: "0px",
                          }}
                        >
                          24 sept 22@10:30 am
                        </p>
                      </Box>
                      <div
                        style={{
                          width: "100%",
                          fontWeight: "300",
                          fontSize: "10px",
                          wordWrap: "break-word",
                        }}
                      >
                        hiiiiasdfghjzssdxcfgvhbdfgchvjbdfgchvjbdxfcghvjbdfghdfghjfcghvjbdfghjfgh
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    sm={2}
                    sx={{ p: "0px !important" }}
                    alignItems="flex-start"
                    justifyContent="flex-end"
                  >
                    <CancelRoundedIcon sx={{ mr: "12px" }} />
                  </Grid>
                  <Divider
                    style={{
                      borderStyle: "dotted",
                      width: "95%",
                      margin: "8px 0px 8px",
                      marginLeft: "14px",
                    }}
                  />
                </Grid>
              </div>
              <div>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    bgcolor: "#fff",
                    pt: "8px !important",
                    pb: "8px !important",
                  }}
                >
                  <Grid
                    item
                    container
                    sm={2}
                    sx={{
                      p: "0px !important",
                      pr: "8px !important",
                      pl: "8px !important",
                    }}
                    // alignItems="center"
                    // justifyContent="center"
                  >
                    <Avatar
                      sx={{
                        bgcolor: getRandomColor(),
                        ml: "8px",
                      }}
                    >
                      N
                    </Avatar>
                  </Grid>
                  <Grid container sm={8} alignItems="center">
                    <Grid item container sm={10}>
                      <Box
                        display="flex"
                        width="100%"
                        mb="8px"
                        alignItems={"center"}
                      >
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            marginBottom: "0px",
                          }}
                        >
                          Navin
                        </p>
                        <Divider
                          orientation="vertical"
                          flexItem
                          variant="center"
                          textAlign="center"
                          style={{
                            margin: "0px 6px 0px 6px",
                            fontWeight: "500",
                            height: "19px",
                            borderRightWidth: 1.1,
                            // backgroundColor: "#000",
                          }}
                        />
                        <p
                          style={{
                            fontSize: "10px",
                            fontWeight: "300",
                            marginBottom: "0px",
                          }}
                        >
                          24 sept 22@10:30 am
                        </p>
                      </Box>
                      <div
                        style={{
                          width: "100%",
                          fontWeight: "300",
                          fontSize: "10px",
                          wordWrap: "break-word",
                        }}
                      >
                        hiiiiasdfghjzssdxcfgvhbdfgchvjbdfgchvjbdxfcghvjbdfghdfghjfcghvjbdfghjfgh
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    sm={2}
                    sx={{ p: "0px !important" }}
                    alignItems="flex-start"
                    justifyContent="flex-end"
                  >
                    <CancelRoundedIcon sx={{ mr: "12px" }} />
                  </Grid>
                  <Divider
                    style={{
                      borderStyle: "dotted",
                      width: "95%",
                      margin: "8px 0px 8px",
                      marginLeft: "14px",
                    }}
                  />
                </Grid>
              </div>
            </Menu>{" "}
            <IconButton color="inherit" onClick={handleClick}>
              {profileImage && <Avatar alt="Remy Sharp" src={profileImage} />}
              {!profileImage && (
                <Avatar
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  {localStorage.getItem("full_name").length == 0
                    ? "P"
                    : localStorage.getItem("full_name").toUpperCase()}
                </Avatar>
              )}
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  setShow5(true);
                  handleClose();
                }}
              >
                Profile
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleClose();
                  setShow(true);
                }}
              >
                Search FSE
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setShow2(true);
                }}
              >
                Lead Generator
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setShow3(true);
                }}
              >
                Report Generator
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setShow4(true);
                }}
              >
                User Validator
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleClose();
                  handleLogout();
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <SwipeableDrawer open={open2} onClose={handleClose2}>
        {list()}
      </SwipeableDrawer>
      <Modal
        style={{ marginTop: "100px" }}
        show={show}
        onHide={() => setShow(false)}
      >
        <FseSearch setShow={setShow} />
      </Modal>
      <LgSearchModal show2={show2} setShow2={setShow2} />
      <RGDownloadModal show3={show3} setShow3={setShow3} />
      <UVModal show4={show4} setShow4={setShow4} />
      <Moda
        open={show5}
        onClose={() => setShow5(false)}
        className="model_contain"
      >
        <ProfileCard setProfileImage={setProfileImage} />
      </Moda>
    </React.Fragment>
  );
};
