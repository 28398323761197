import React from "react";

function RepDetails({ data }) {
  return data.length == 0 ? (
    <>
      <img
        src={window.location.origin + "/images/confirmAnimation.gif"}
        width="25%"
      />
      {setTimeout(() => {
        window.location.reload();
      }, 1500)}
    </>
  ) : (
    <>
      <div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">USER ID</th>
              <th scope="col">Request ID</th>
              <th scope="col">Message</th>
            </tr>
          </thead>
          <tbody>
            {data.map((res) => {
              return (
                <tr>
                  <th scope="row">{res?.user_id}</th>
                  <td>{res?.request_id}</td>
                  <td>{res?.message}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default RepDetails;
