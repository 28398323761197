import axios from "axios";
import React, { useEffect, useState } from "react";
import { upload_profile_photo, user_info } from "../../utils";
import FeatherIcon from "feather-icons-react";
import "../css/ProfileCard.css";
import { dataToken, fin_id } from "../../Config/token";
import { CircularProgress } from "@mui/material";
function ProfileCard({ setProfileImage }) {
  const [userData, setUserdata] = useState({});
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const postData = {
      fin_id: fin_id,
    };
    axios
      .post(user_info, postData, dataToken)
      .then((res) => {
        setUserdata(res.data.user_data);
        setUrl(res.data.user_data.profile_photo);
        setProfileImage(res.data.user_data.profile_photo);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const imageUpload1 = (e) => {
    setLoading(true);
    const axiosData = new FormData();
    axiosData.append("file", e.target.files[0]);
    axiosData.append("id", userData.id);
    axiosData.append("type", "profile_photo");
    axios
      .post(upload_profile_photo, axiosData, dataToken)
      .then((res) => {
       
        setUrl(res.data.url);
        setProfileImage(res.data.url);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  return (
    <div className="card-container">
      <span className="pro">F-ID:{userData.id}</span>
      {userData?.profile_photo ? (
        <img
          src={url}
          alt="user"
          className="img_css_profile"
          onError={(e) => {
            e.target.src = `${window.location.origin}/images/power.svg`;
          }}
        />
      ) : (
        <div className="non_img_css_profile_outside">
          <div className="non_img_css_profile">
            {userData.full_name ? userData.full_name[0] : ""}
          </div>
        </div>
      )}
      <div>
        <div id="resumeSel" className="edit_profile_css">
          <label className="label_css">
            {loading ? (
              <CircularProgress size={24} className="text-light" />
            ) : (
              <FeatherIcon icon="edit" size={24} />
            )}
            <input
              type="file"
              onChange={(e) => {
                imageUpload1(e);
              }}
            />
          </label>
        </div>
      </div>

      <h3 className="name_css mt-4">{userData.full_name}</h3>
      <span className="email_css">{userData.mobile_number}</span>
    </div>
  );
}

export default ProfileCard;
