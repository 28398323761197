import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import InvoiceModal2 from "./InvoiceModal2";
import axios from "axios";
import {
  fseDetails,
  fseDetailsNoFo,
  invoiceDetails,
  leadDetails,
} from "../../utils";
// import FseDetailsTable from "./FseDetailsTable";
// import LeadDetailsTable from "./LeadDetailsTable";
import PayoutDetails from "./PayoutDetails";
import NoFoTable from "./NoFoTable";
import Agreement from "./Agreement";
import { useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MainDetailsPage = () => {
  // const [leadData, setLeadData] = React.useState([]);
  const invoiceId = localStorage.getItem("invoiceId");
  // const refTableName = localStorage.getItem("reftableName");
  const [value, setValue] = React.useState(0);
  const [indata, setIndata] = React.useState("");
  // const [dataList, setDataList] = React.useState(undefined);
  const [dataListNofo, setDataListNofo] = React.useState(undefined);
  const token = localStorage.getItem("token");
  const config = {
    headers: { "x-access-token": ` ${token}` },
  };

  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const postData = {
      invoice_id: invoiceId,
      year: "2022",
    };
    axios
      .post(invoiceDetails, postData, config)
      .then((res) => {
        setIndata(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  // const fseDetailsData = async () => {
  //   const data = { invoice_id: localStorage.getItem("invoiceId") };
  //   await axios
  //     .post(fseDetails, data, config)
  //     .then((res) => {
  //       setDataList(res?.data?.data);
  //     })
  //     .catch((e) => {
  //
  //     });
  // };

  const fseDeatailsNoFoData = async () => {
    const data = { invoice_id: localStorage.getItem("invoiceId") };
    await axios
      .post(fseDetailsNoFo, data, config)
      .then((res) => {
        setDataListNofo(res?.data?.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // const leadDetailsData = async () => {
  //   let invoiceId = localStorage.getItem("invoiceId");
  //   let reftableName = localStorage.getItem("reftableName");
  //   let token = localStorage.getItem("token");
  //   const postData = {
  //     invoice_id: invoiceId,
  //     ref_table_name: reftableName,
  //   };
  //   const config = {
  //     headers: { "x-access-token": ` ${token}` },
  //   };

  //   await axios
  //     .post(leadDetails, postData, config)
  //     .then((res) => {
  //       setLeadData(res.data.lead);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Payout Details"
              {...a11yProps(0)}
              style={{ marginLeft: "12px" }}
            />
            <Tab
              label="Fse Details"
              {...a11yProps(1)}
              style={{ marginLeft: "12px" }}
              onClick={fseDeatailsNoFoData}
            />
            <Tab
              label="Documents"
              {...a11yProps(1)}
              style={{ marginLeft: "12px" }}
              onClick={fseDeatailsNoFoData}
            />
            <Tab
              label="Invoice"
              {...a11yProps(2)}
              style={{ marginLeft: "12px" }}
            />
            {/* {!(refTableName == "null") && (
              <Tab
                label="FSE Details"
                {...a11yProps(3)}
                onClick={fseDetailsData}
              />
            )}
            {!(refTableName == "null") && (
              <Tab
                label="Lead Details"
                {...a11yProps(4)}
                onClick={leadDetailsData}
              />
            )} */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <PayoutDetails />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <NoFoTable data={dataListNofo} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Agreement />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <InvoiceModal2 inData={indata} innId={invoiceId} year={"2022"} />
        </TabPanel>
        {/* {!(refTableName == "null") &&
          <TabPanel value={value} index={3}>
            {dataList != "undefined" && <FseDetailsTable data={dataList} />}
          </TabPanel>}
          {!(refTableName == "null") &&
          <TabPanel value={value} index={4}>
            {leadData != "undefined" && (
              <LeadDetailsTable leadData={leadData} />
            )}
          </TabPanel>} */}
      </Box>
    </div>
  );
};

export default MainDetailsPage;
