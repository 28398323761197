import React, { useEffect, useState } from "react";
import "./Payout.css";
import Modal from "react-bootstrap/Modal";
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import AddPayout from "./AddPayout";
import axios from "axios";
import {
  getpaymentRequest,
  sow_rep,
  process_payment,
  getInvoices,
  movetoInvoice,
  getInvtomove,
} from "../../utils";
import { dataToken, token } from "../../Config/token";
import FeatherIcon from "feather-icons-react";
import RepDetails from "./RepDetails";
import CircularProgress from "@mui/material/CircularProgress";
import UpdateDetails from "./UpdateDetails";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Grid,
} from "@mui/material";
import { MoreVert } from "@material-ui/icons";
import Dump from "../Modal/Dump";
import Chip from "@mui/material/Chip";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { token_type } from "../../Config/token";
import Swal from "sweetalert2";

createTheme("solarized", {
  text: {
    primary: "black",
    secondary: "#b2b2b2",
  },
  background: {
    default: "#fff",
  },
  boxshadow: {
    default: "5px 5px 30px #DEDEDEBF",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#f1f1f1",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

function PayoutDetails() {
  const invoiceId = localStorage.getItem("invoiceId");
  const type = token_type;

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [data, setData] = useState([]);
  const [dumpData, setDumpdata] = useState({});
  const [updateDetailsData, setUpdateDetailsData] = useState({});
  const [leadData, setleadData] = useState([]);
  const [totalcount, setTotalcount] = useState(0);
  const [billableTotal, setBillableTotal] = useState(0);
  const [dlHeight, setDlHeight] = useState(false);

  const [show3, setShow3] = React.useState(false);
  const [show5, setShow5] = React.useState(false);
  const [requId, setRequId] = React.useState("");
  const [sowwId, setSowwId] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [updateboolProcess, setupdateBoolProcess] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [InvoiceOptions, setInvoiceOptions] = React.useState([]);
  const [invoice, setInvoice] = React.useState("");
  const [table, setTable] = useState({});

  const location = useLocation();
  const state = location.state;
  console.log(state, "asd");

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onclickOpenSureD = (d) => {
    setSowwId(d.sow_id);
    setRequId(d.req_id);
    setShow3(true);
  };

  const handleOpenMoveToInvoice = () => {
    setShow5(true);
  };

  const handleCloseSureD = () => {
    setShow3(false);
  };

  const handleClosMoveToInvoice = () => {
    setShow5(false);
    setInvoice("");
  };

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const navigate = useNavigate();

  const [show4, setShow4] = React.useState(false);

  const handleOpenUploadDump = () => {
    setShow4(true);
  };

  const handleMoveInvoiceSubmit = async () => {
    const body = {};
    body.invoice_id_before = state.invoice_id_before;
    body.invoice_id_after = invoice.value;
    body.id = table.req_id;

    //   axios
    //     .put(movetoInvoice, body, dataToken)
    //     .then((res) => console.log(res.message))
    //     .catch((err) => console.log(err));

    try {
      let res = await axios.put(movetoInvoice, body, dataToken);
      if (res) {
        setShow5(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "success",
          text: `Request ${table.req_id}as moved from invoice number before to invoice number after `,
          showConfirmButton: true,
        });
        navigate("/invoice2022");
      }
    } catch (error) {
      console.log(error, "errmessage");
    }
  };

  useEffect(() => {
    const data = {
      invoice_id: invoiceId,
    };
    axios
      .post(getpaymentRequest, data, dataToken)
      .then((res) => {
        setBillableTotal(res.data.billable_total);
        setleadData(res.data.data);
        setTotalcount(res.data.total);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [updateboolProcess]);

  React.useEffect(() => {
    const data = {};
    data.year = state.year;
    axios
      .post(getInvtomove, data, dataToken)
      .then((res) => {
        setInvoiceOptions(res.data.invoice);
      })
      .catch((err) => console.log(err));
  }, []);

  const openUpdateModal = (d) => {
    let ot = {
      type: d.type,
      cpl: d.p_cpl,
      leads: d.leads,
      billable_leads: d.billable_leads,
      request_id: d.req_id,
      billing_amt: d.billing_amt,
      start_date: d.start_date,
      end_date: d.end_date,
    };
    setUpdateDetailsData(ot);
    setShow2(true);
  };

  const processPaymentFunc = () => {
    handleCloseSureD();

    const data = {
      req_id: requId,
      sow_id: sowwId,
    };

    setIsLoading(true);
    axios
      .post(process_payment, data, dataToken)
      .then((res) => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
        setupdateBoolProcess(!updateboolProcess);
      });
  };
  const columns = [
    {
      name: "Payment Type",
      selector: "type",
      cell: (d) => (
        <div className="loanid">
          {d.type.split("_")[0]} {d.type.split("_")[1]}
        </div>
      ),
      sortable: true,
      center: true,
      width: "175px",
    },
    {
      name: "ID",
      selector: "req_id",
      cell: (d) => <div className="loanid">{d.req_id}</div>,
      sortable: true,
      center: true,
      width: "70px",
    },
    {
      name: "SOW ID",
      selector: "sow_id",

      cell: (d) => (
        <div>
          <div className="loanid">{d.sow_id}</div>
        </div>
      ),
      sortable: true,
      center: true,
      width: "100px",
    },
    {
      name: " Date",
      selector: "display_date",
      cell: (d) => <div className="date">{d.display_date}</div>,
      // sortable: true,
      center: true,
      width: "165px",
    },

    {
      name: "b_l * b_a",
      selector: "billable_leads",
      cell: (d) => (
        <div className="amt_table">
          {String(d.billable_leads) + "*" + String(d.billing_amt)}
        </div>
      ),
      sortable: true,
      center: true,
      width: "120px",
    },
    {
      name: "b_total",

      cell: (d) => (
        <div className="amt_table">{d.billable_leads * d.billing_amt}</div>
      ),
      sortable: true,
      center: true,
      width: "120px",
    },
    {
      name: "t_l * cpl",
      // selector: "leads",
      width: "120px",
      cell: (d) => (
        <div>
          {d.type == "auto_payment" && (
            <a
              href={`${sow_rep}?token=${token}&sow_id=${d.sow_id}&start_date=${d.start_date}&end_date=${d.end_date}&type=qc`}
            >
              <div
                className="amt_table"
                style={{
                  padding: "5px 10px",
                  fontSize: "15px",
                  cursor: "pointer",
                }}
              >
                {String(d.leads) + "*" + String(d.p_cpl)}
              </div>
            </a>
          )}
          {d.type != "auto_payment" && (
            <div className="amt_table">
              {String(d.leads) + "*" + String(d.p_cpl)}
            </div>
          )}
        </div>
      ),
      sortable: true,
      center: true,
    },
    {
      name: "t_amt",
      // selector: "cpl",
      cell: (d) => <div className="amt_table">{d.p_cpl * d.leads}</div>,
      sortable: true,
      center: true,
      width: "120px",
    },
    // {
    //   name: "remark",
    //   selector: "remark",
    //   cell: (d) => <div className="amt_table">{d.remark}</div>,
    //   sortable: true,
    //   center: true,
    //   width: "100px",
    // },
    {
      name: " DAys left",
      selector: (d) => d.days_left,
      cell: (d) => (
        <div>
          {d.paid > "0" ? (
            <Chip label={`Paid`} color="success" />
          ) : d.days_left > 0 ? (
            <Chip label={`Due in ${d.days_left} Days`} color="success" />
          ) : d.days_left < 0 ? (
            <Chip
              label={`Overdue by ${Math.abs(d.days_left)} Days`}
              color="error"
            />
          ) : (
            <Chip label={`Not Updated`} color="warning" />
          )}
        </div>
      ),
      sortable: true,
      center: true,
      width: "140px",
    },
    // {
    //   name: "process payment",
    //   width: "100px",
    //   center: "true",
    //   cell: (d) => (
    //     <div>
    //       {isLoading ? (
    //         <>
    //           <CircularProgress />
    //         </>
    //       ) : (
    //         <div
    //           onClick={() => {
    //             d.count > 0 && d.status !== "processed" && onclickOpenSureD(d);
    //           }}
    //           style={{ cursor: "pointer" }}
    //         >
    //           {d.count == 0 ? (
    //             <img
    //               src={
    //                 window.location.origin + "/images/process_payment_grey.svg"
    //               }
    //             />
    //           ) : d.count > 0 && d.status !== "processed" ? (
    //             <img
    //               src={
    //                 window.location.origin +
    //                 "/images/process_payment_purple.svg"
    //               }
    //             />
    //           ) : (
    //             <img
    //               src={
    //                 window.location.origin + "/images/process_payment_green.svg"
    //               }
    //             />
    //           )}
    //         </div>
    //       )}
    //     </div>
    //   ),
    // },
    {
      name: "action",
      width: "100px",
      center: "true",
      cell: (d) => (
        <div>
          <div
            style={{ cursor: "pointer" }}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(e) => {
              handleClick(e);
              setDumpdata(d);
              setTable(d);
            }}
          >
            <MoreVert />
          </div>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                dumpData.paid == "0" && openUpdateModal(d);
              }}
              style={{
                border: "none",
                filter: dumpData.paid > "0" ? "blur(4px)" : "",
              }}
            >
              <img src={window.location.origin + "/images/edit.svg"} />
              &nbsp;&nbsp;&nbsp; Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                handleOpenUploadDump();
              }}
            >
              <FeatherIcon icon="upload-cloud" size={24} /> &nbsp;&nbsp;&nbsp;
              Upload
            </MenuItem>
            {type == "admin" && state?.recon == "no" ? (
              <MenuItem
                onClick={() => {
                  handleOpenMoveToInvoice();
                }}
              >
                <FeatherIcon icon="log-in" size={24} /> &nbsp;&nbsp;&nbsp; Move
                To Invoice
              </MenuItem>
            ) : (
              ""
            )}
          </Menu>
        </div>
      ),
    },
  ];

  const dataTable = {
    columns: columns,
    data: leadData,
  };
  return (
    <div className="d-flex flex-column">
      <div className="add_box">
        <div
          style={{
            marginRight: "200px",
            position: "absolute",
            border: "1px solid #663595",
            background: "#663595",
            height: "40px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            padding: "5px",
            width: "200px",
          }}
        >
          Total Payout: &nbsp;{" "}
          <span style={{ fontWeight: "900", fontSize: "18px" }}>
            {Math.floor(Number(totalcount))}
          </span>
        </div>
        <button className="button_blue1" onClick={() => setShow(true)}>
          <img
            src={window.location.origin + "/images/add.svg"}
            alt="ott"
            className="button_add"
          />
          Add
        </button>
      </div>

      <div className="dash_container">
        <div className="table_data">
          <div className="card_table">
            <DataTableExtensions
              {...dataTable}
              filterPlaceholder={`Search`}
              className="extension_option"
            >
              <DataTable
                columns={columns}
                data={leadData}
                defaultSortFieldId={1}
                pagination
                paginationPerPage={5}
                theme="solarized"
              />
            </DataTableExtensions>
          </div>
        </div>
      </div>
      <Modal centered show={show} onHide={() => setShow(false)}>
        <div className="add_page">
          <AddPayout />
        </div>
      </Modal>
      <Modal centered show={show1} onHide={() => setShow1(false)}>
        <div className="add_page">
          <RepDetails data={data} />
        </div>
      </Modal>
      {show2 && (
        <Modal centered show={show2} onHide={() => setShow2(false)}>
          <div className="add_page">
            <UpdateDetails inputData={updateDetailsData} dumpData={dumpData} />
          </div>
        </Modal>
      )}
      {show4 && (
        <Modal centered show={show4} onHide={() => setShow4(false)}>
          <div className="add_page">
            <Dump d={dumpData} setShow4={setShow4} />
          </div>
        </Modal>
      )}
      {/* are you sure dialogue */}
      {show3 && (
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={show3}
          onClose={handleCloseSureD}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Process Payment"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to Process this payment.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={processPaymentFunc}>
              Yes
            </Button>
            <Button onClick={handleCloseSureD} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Dialog
        fullWidth={fullWidth}
        maxWidth={"md"}
        // PaperProps={{ sx: { width: "50%", height: "30%" } }}
        open={show5}
        onClose={() => {
          handleClosMoveToInvoice();
          setDlHeight(false);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle style={{ color: "#663595" }}>Move To Invoice</DialogTitle>
        <DialogContent
          style={{ height: dlHeight ? "80px" : "80px" }}
          onClick={() => setDlHeight(true)}
        >
          <Grid container sx={{ p: "12px" }}>
            <Grid item xs={10} spacing={2}>
              <Select
                isSearchable={true}
                options={InvoiceOptions}
                menuPosition="fixed"
                onChange={(value) => {
                  setInvoice(value);
                }}
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                size={"medium"}
                disabled={invoice == "" ? true : false}
                onClick={handleMoveInvoiceSubmit}
                style={{
                  backgroundColor: invoice == "" ? "grey" : "#663595",
                  color: invoice == "" ? "#fff" : "#fff",
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default PayoutDetails;
