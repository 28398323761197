import React, { useContext, useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";

import DataTableExtensions from "react-data-table-component-extensions";

import axios from "axios";

import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import { dataToken, token_type } from "../config/token";
// import { Context } from "../../App";
import { Button, Menu, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import GppBadIcon from "@mui/icons-material/GppBad";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import { getpaymentRequestOps } from "../../utils";
import { dataToken } from "../../Config/token";
// import "../css/Request.css";
import RequestModalEdit from "../Modal/RequestModalEdit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
createTheme("VendorTable", {
  text: {
    primary: "black",
    secondary: "#b2b2b2",
  },
  background: {
    default: "#fff",
  },
  boxshadow: {
    default: "5px 5px 30px #DEDEDEBF",
  },
  headCells: {
    default: "#64abdd",
  },
  divider: {
    default: "#f4f4f4",
  },
  cell: {
    style: {
      height: "60px !important",
    },
  },

  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});

function Request() {
  const navigate = useNavigate();

  const [error, setError] = React.useState(false);
  const { id } = useParams();
  const params = useParams();
  const [data, setData] = React.useState([]);
  const [ops, setOps] = React.useState("");
  const [singleData, setSingledata] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const openMenu2 = Boolean(anchorEl2);
  const [req, setReq] = useState("notprocessed");
  const [reqid, setReqId] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  useEffect(() => {
    const data = {
      status: req,
    };

    axios
      .post(getpaymentRequestOps, data, dataToken)
      .then((res) => {
        setData(res.data.data);
        setOps(res.data);
      })
      .catch((err) => console.log(err));
  }, [req]);

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = (d) => {
    setOpen(true);
  };
  const handleCloseEditModal = () => {
    setOpen(false);
    setError(false);
  };

  const handleSplitRequest = () => {
    let ot = JSON.parse(sessionStorage.getItem("currentData"));
    navigate(`/splitrequest/${ot.req_id}`);
    // console.log(d,'dfasak')
    // sessionStorage.setItem('currentData',JSON.stringify(d))
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  const columns = [
    {
      name: "Sow Id",
      selector: "sow_id",
      cell: (d) => <div className="amt_table">{d.sow_id}</div>,
      sortable: true,
      center: true,
      width: "100px",
    },
    {
      name: "Leads",
      selector: "leads",
      cell: (d) => <div className="amt_table">{d.leads}</div>,
      sortable: true,
      center: true,
      width: "100px",
    },
    {
      name: "Cpl",
      selector: "p_cpl",
      cell: (d) => <div className="amt_table">{d.p_cpl}</div>,
      sortable: true,
      center: true,
      width: "80px",
    },

    {
      name: "Request date",
      selector: "request_date",
      cell: (d) => <div className="date">{d.request_date}</div>,
      sortable: true,
      center: true,
      width: "120px",
    },
    {
      name: " OPs Remark",
      selector: "request_remark",
      cell: (d) => <div className="amt_table">{d.req_remark}</div>,
      sortable: true,
      center: true,
      width: "180px",
    },
    {
      name: "date",
      selector: "display_date",
      cell: (d) => <div className="amt_table">{d.display_date}</div>,
      sortable: true,
      left: true,
      width: "100px",
      center: true,
      width: "180px",
    },

    {
      name: "type",
      selector: "user_type",
      cell: (d) => (
        <div className="amt_table">
          {d.user_type == "direct_sp"
            ? "Tsm"
            : d.user_type == "indirect_sp"
            ? "Vendor"
            : d.user_type}
        </div>
      ),
      sortable: true,
      left: true,
      width: "100px",
      center: true,
      width: "180px",
    },

    {
      name: "status",
      selector: "status",
      sortable: true,
      center: true,
      width: "100px",
      cell: (d) => (
        <div>
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit">{d.remark}</Typography>
                {/* <em>{"And here's"}</em> <b>{"some"}</b>{" "}
                <u>{"amazing content"}</u>. {"It's very engaging. Right?"} */}
              </React.Fragment>
            }
          >
            {d.status == "processed" ? (
              <VerifiedUserIcon style={{ color: "green" }} />
            ) : d.status == "failed" ? (
              <GppBadIcon style={{ color: "red" }} />
            ) : (
              <GppMaybeIcon style={{ color: "grey" }} />
            )}
          </HtmlTooltip>
        </div>
      ),
    },
    {
      name: "Requested By",
      selector: "req_name",
      cell: (d) => <div className="amt_table">{d.req_name}</div>,
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: "Phone Number",
      selector: "req_num",
      cell: (d) => <div className="amt_table">{d.req_num}</div>,
      sortable: true,
      center: true,
      width: "130px",
    },
    {
      name: "process",
      cell: (d) => (
        <div>
          <div>
            <Button
              onClick={(e) => {
                setSingledata(d);
                handleClick(e);
                setReqId(d.req_id);
                sessionStorage.setItem("currentData", JSON.stringify(d));
              }}
            >
              <MoreVertIcon />
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    // data: context.paymentrequest,
    data,
  };

  return (
    <>
      <Button
        style={{
          position: "absolute",
          // right: "-1300px",
          right: "50px",
          top: "160px",
          zIndex: "99",
        }}
        onClick={handleClick2}
        variant="contained"
        startIcon={<FilterAltIcon />}
      >
        filter
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl2}
        open={openMenu2}
        onClose={handleClose2}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose2();
            setReq("all");
          }}
        >
          All
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose2();
            setReq("processed");
          }}
        >
          Processed
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose2();
            setReq("notprocessed");
          }}
        >
          Not Processed
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose2();
            setReq("rejected");
          }}
        >
          Rejected
        </MenuItem>
      </Menu>

      <div className="table_data">
        <div
          style={{
            display: "flex",
            gap: "12px",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            marginRight: "12px",
          }}
        >
          <div
            style={{
              marginBottom: "0px",
              border: "1px solid #663595",
              background: "#663595",
              height: "40px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              padding: "5px",
              width: "200px",
            }}
          >
            Vendor: &nbsp;
            <span style={{ fontWeight: "900", fontSize: "18px" }}>
              {ops.vendor}
            </span>
          </div>
          <div
            style={{
              marginBottom: "0px",
              border: "1px solid #663595",
              background: "#663595",
              height: "40px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              padding: "5px",
              width: "200px",
            }}
          >
            Tasker: &nbsp;
            <span style={{ fontWeight: "900", fontSize: "18px" }}>
              {ops.tasker}
            </span>
          </div>
          <div
            style={{
              marginBottom: "0px",
              border: "1px solid #663595",
              background: "#663595",
              height: "40px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              padding: "5px",
              width: "200px",
            }}
          >
            TSM: &nbsp;
            <span style={{ fontWeight: "900", fontSize: "18px" }}>
              {ops.tsm}
            </span>
          </div>
        </div>
        <div className="card_table">
          <DataTableExtensions
            {...tableData}
            export={false}
            filterPlaceholder={`Search`}
            style={{ paddingRight: "25px important" }}
          >
            <DataTable
              columns={columns}
              data={tableData}
              defaultSortFieldId={3}
              pagination
              theme="VendorTable"
              highlightOnHover={true}
              paginationPerPage={5}
            />
          </DataTableExtensions>
        </div>
      </div>
      <RequestModalEdit
        openEditModal={open}
        handleCloseEditModal={handleCloseEditModal}
        error={error}
        setError={setError}
        singleData={singleData}
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClickOpen();

            handleClose();
          }}
        >
          Process
        </MenuItem>
        {singleData.status == "none" && singleData.split_status == "none" && (
          <>
            <MenuItem
              onClick={() => {
                handleSplitRequest();
              }}
            >
              Split Request
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
}

export default Request;
