import React, { createContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import Nav from "./components/navbar/Nav";
import { financeData } from "./statesInitializer";
import Login123 from "./components/Login/Otp";
import Login from "./components/Login/Number";
import Invoice2022 from "./components/pages/Invoice2022";
import { dataToken, token } from "./Config/token";
import axios from "axios";
import { extract_token } from "./utils";
import { Nav2 } from "./components/navbar/Nav2";
import Invoice2020 from "./components/pages/Invoice2020";
import Invoice2021 from "./components/pages/Invoice2021";
import ClientTable from "./components/pages/ClientTable";
import Request from "./components/pages/Request";
import Processreq from "./components/pages/Processreq";
import SplitRequest from "./components/pages/SplitRequest";

export const FinanceContext = createContext();
function App() {
  const [context, setContext] = useState({ ...financeData });
  const [login, setLogin] = useState(false);
  const navigate = useNavigate();
  const params = window.location.pathname.replace("/", "");

  useEffect(() => {
    if (token !== "null") {
      axios
        .get(extract_token, dataToken)
        .then((res) => {
          if (!res.data.error && res.data.status == "active") {
            setLogin(true);
            context.navData = res.data;
            setContext({ ...context });
            switch (params) {
              case `${params}`:
                navigate(`/${params}`);
                break;
              default:
                navigate("/invoice2022");
            }
          } else {
            setLogin(false);
            localStorage.removeItem("token");
            // localStorage.removeItem("type");
            navigate("/login");
          }
        })
        .catch((err) => {
          setLogin(false);
          localStorage.removeItem("vendor-token");
          localStorage.removeItem("type");
          navigate("/login");
        });
    } else {
      setLogin(false);
      localStorage.removeItem("vendor-token");
      localStorage.removeItem("type");
      navigate("/login");
    }
  }, []);

  const assignLogin = (e) => {
    setLogin(e);
  };
  return (
    <FinanceContext.Provider value={[context, setContext]}>
      <div className="App">
        {login ? (
          <>
            <div style={{ width: `100%` }}>
              <Nav2 />
              <Routes>
                <Route path="/invoice2023" element={<Invoice2022 />} />
                <Route path="/invoice2022" element={<Invoice2022 />} />
                <Route path="/mainDetails" element={<Invoice2022 />} />
                <Route path="/invoice2021" element={<Invoice2021 />} />
                <Route path="/invoice2020" element={<Invoice2020 />} />
                <Route path="/client" element={<ClientTable />} />
                {/* <Route path="/request" element={<Request />} /> */}
                <Route path="/splitrequest/:id" element={<SplitRequest />} />
                {/* <Route path="/process_request" element={<Processreq />} /> */}
              </Routes>
            </div>
          </>
        ) : (
          <>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/login/otp"
                element={<Login123 assignLogin={assignLogin} />}
              />
            </Routes>
          </>
        )}
      </div>
    </FinanceContext.Provider>
  );
}

export default App;
