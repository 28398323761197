import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { invoiceDetails, invoiceList } from "../../utils";
import InvoiceModal from "./InvoiceModal";
import DownloadIcon from "@mui/icons-material/Download";
createTheme("solarized", {
  text: {
    primary: "black",
    secondary: "#b2b2b2",
  },
  background: {
    default: "#fff",
  },
  boxshadow: {
    default: "5px 5px 30px #DEDEDEBF",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#f1f1f1",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});
function Invoice2020() {
  const [datalist, setDatalist] = useState([]);
  const [inData, setIndata] = useState({});
  const [innId, setinvid] = useState("");
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");
  const config = {
    headers: { "x-access-token": ` ${token}` },
  };

  useEffect(() => {
    const data = {
      year: "2020",
    };
    axios
      .post(invoiceList, data, config)
      .then((res) => {
        setDatalist(res.data.invoices);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleModel = (data) => {
    const postData = {
      invoice_id: data.invoice_id,
      year: "2020",
    };
    axios
      .post(invoiceDetails, postData, config)
      .then((res) => {
        setIndata(res.data);
        setinvid(data.invoice_id);
        setOpen(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const columns = [
    {
      name: "invoice id",
      selector: "invoice_id",
      cell: (d) => <div className="loanid">{d.invoice_id}</div>,
      sortable: true,
      center: true,
    },
    {
      name: "DATE",
      selector: "date",
      cell: (d) => <div className="date">{d.billed_date}</div>,
      sortable: true,
      center: true,
    },
    {
      name: "AMOUNT",
      selector: "amount",
      cell: (d) => <div className="amt_table">&#x20B9;{d.amount}</div>,
      sortable: true,
      center: true,
    },
    {
      name: "Invoice",
      selector: "number",
      cell: (d) => {
        return (
          <>
            <img
              src={window.location.origin + "/images/ic_eye.svg"}
              alt="ott"
              className="paper"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleModel(d);
              }}
            />
            <InvoiceModal
              handleClose={handleClose}
              open={open}
              inData={inData}
              innId={innId}
              year={"20-21"}
            />
          </>
        );
      },
      sortable: true,
      center: true,
    },
    {
      name: "Download",
      selector: "download",
      cell: (d) => (
        <div className="amt_table" style={{ cursor: "pointer" }}>
          <a
            href={`https://farming-backend.taskmo.in/invtoken/downloadinvoice2020/2020?invoice_id=${d.invoice_id}&token=${token}`}
            target="_blank"
          >
            <DownloadIcon />
          </a>
        </div>
      ),
      sortable: true,
      center: true,
    },
  ];
  const dataTable = {
    columns,
    data: datalist,
  };
  return (
    <div>
      <div className="d-flex justify-content-between heading_dash">
        <div className="d-flex flex-column">
          <span className="headimg_team"> Invoice 2020</span>
        </div>
      </div>
      <div className="dash_container">
        <div className="table_data">
          <div className="menu_container">
            {/* <h1 className="tableTitle">My Invoices</h1> */}
          </div>
          <div className="card_table">
            <DataTableExtensions
              {...dataTable}
              filterPlaceholder={`Search`}
              className="extension_option"
            >
              <DataTable
                columns={columns}
                data={datalist}
                defaultSortFieldId={1}
                pagination
                paginationPerPage={7}
                theme="solarized"
                expandableRows={true}
                // expandableRowsHideExpander
                // expandOnRowClicked={true}
              />
            </DataTableExtensions>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoice2020;
