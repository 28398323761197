import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {
  invoiceDetails,
  invoiceList,
  process_invoice,
  update_billing_date,
  client_details,
  createClient,
  update_client,
} from "../../utils";
import "../teamdash/TeamDash.css";
import InvoiceModal from "./InvoiceModal";
import MainDetailsPage from "./MainDetailsPage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FseSearch from "./FseSearch";
import Swal from "sweetalert2";
import AddIcon from "@mui/icons-material/Add";
import FeatherIcon from "feather-icons-react";
import Textarea from "@mui/joy/Textarea";

import moment from "moment";
import {
  Button as Submitbutton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
} from "@mui/material";
import { Button } from "reactstrap";

import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { dataToken, token_type } from "../../Config/token";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextareaAutosize,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

createTheme("solarized", {
  text: {
    primary: "black",
    secondary: "#b2b2b2",
  },
  background: {
    default: "#fff",
  },
  boxshadow: {
    default: "5px 5px 30px #DEDEDEBF",
  },
  context: {
    background: "#cb4b16",
    text: "#FFFFFF",
  },
  divider: {
    default: "#f1f1f1",
  },
  action: {
    button: "rgba(0,0,0,.54)",
    hover: "rgba(0,0,0,.08)",
    disabled: "rgba(0,0,0,.12)",
  },
});
function ClientTable() {
  const invoiceNo = Number(localStorage.getItem("invoiceId"));
  const [datalist, setDatalist] = useState([]);
  const [inData, setIndata] = useState({});
  const [innId, setinvid] = useState("");
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation();
  
  const config = {
    headers: { "x-access-token": ` ${token}` },
  };

  const [showCI, setShowCI] = React.useState(false);

  const openModal = () => {
    setShowCI(true);
  };

  const closeModal = () => {
    setShowCI(false);
  };

  const currencies = [
    {
      value: "chooseclient1",
      label: "chooseclient1",
    },
    {
      value: "chooseclient2",
      label: "chooseclient2",
    },
    {
      value: "chooseclient3",
      label: "chooseclient3",
    },
  ];

  const [currency, setCurrency] = React.useState("select client");
  const [open1, setOpen1] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [maxWidth2, setMaxWidth2] = React.useState("lg");
  const [invId, setInvId] = React.useState("");
  const [scroll, setScroll] = React.useState("paper");
  const initStateForm = {
    fullname: "",
    emailid: "",
    phoneno: "",
    designation: "",
    department: "",
    gstno: "",
    companyname: "",
    brand_name: "",
    website: "",
    industry: "",
    organization: "",
    state: "",
    cin: "",
    about_company: "",
    corporate_address: "",
    billing_address: "",
  };
  const [formData, setFormData] = React.useState({});

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClickOpen = (d) => {
    setInvId(d.invoice_id);
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleUpdate = () => {
    if (value == "" || value1 == "") return "";
    
    const postData = {
      start_date: value,
      end_date: value1,
      inv_id: invId,
    };
    axios
      .post(update_billing_date, postData, dataToken)
      .then((res) => {
        

        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [value, setValue] = React.useState("");
  const [value1, setValue1] = React.useState("");
  const [minDate, setMinDate] = React.useState("");
  const [billingInvoiceId, setbillingInvoiceId] = React.useState("");
  const [viewOpen, setViewOpen] = React.useState(false);
  const [currentData, setCurrentData] = React.useState({});
  const [editClient, setEditClient] = React.useState({});
  
  const handleClientEdit = (e) => {
    const { name, value } = e.target;

    setEditClient({ ...editClient, [name]: value });
  };
  const handleViewOpen = () => {
    setViewOpen(true);
  };

  const handleViewClose = () => {
    setEditClient({}); //setting data to empty on closing of the modal
    setViewOpen(false);
  };

  const handleChange = (newValue) => {
    const date = Number(newValue.$D) < 10 ? `0${newValue.$D}` : newValue.$D;
    const dateTime = `${newValue.$y}-${newValue.$M + 1}-${date}`;
    
    setMinDate(newValue);
    // const ot1 = moment(newValue).format('YYYY-MM-DD')
    
    setValue(dateTime);
  };

  const handleChange1 = (newValue) => {
   
    const date = Number(newValue.$D) < 10 ? `0${newValue.$D}` : newValue.$D;
    const dateTime2 = `${newValue.$y}-${newValue.$M + 1}-${date}`;

    setValue1(dateTime2);
  };

  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handlebilledDetails = () => {
    const postData = { inv_id: billingInvoiceId };
    
    axios
      .post(process_invoice, postData, dataToken)
      .then((res) => {
       
        handleClose2();
        Swal.fire({
          icon: "success",
          text: "Submitted Successfully",
          iconColor: "#54B701",
          color: "#102464",
          confirmButtonColor: "#102464",
          title: "Success",
          confirmButtonText: "Done",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops!",
          text: `${err.response.data.message}`,
        });
      });
  };

  const [age, setAge] = React.useState("");

  const handleChangeAgeform = (event) => {
    setAge(event.target.value);
  };

  useEffect(() => {
    const data = {
      year: "2022",
    };
    axios
      .get(client_details, config)
      .then((res) => {
        setDatalist(res.data.client);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleModel = (data) => {
    const postData = {
      invoice_id: data.invoice_id,
      year: "2022",
    };
    axios
      .post(invoiceDetails, postData, config)
      .then((res) => {
        setIndata(res.data);
        setinvid(data.invoice_id);
        setOpen(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const columns = [
    {
      name: "Client Id",
      selector: "clientid",
      cell: (d) => <div className="loanid">{d.clientid}</div>,
      sortable: true,
      width: "120px",
      center: true,
    },
    {
      name: "Client Name",
      selector: "fullname",
      cell: (d) => <div className="colNmae">{d.fullname}</div>,
      sortable: true,
      width: "200px",
      center: true,
    },
    {
      name: "phone no.",
      selector: "phoneno",
      cell: (d) => <div className="loanid">{d.phoneno}</div>,
      sortable: true,
      width: "200px",
      center: true,
    },
    {
      name: "Billing Address",
      selector: "billing_address",
      cell: (d) => <div className="colNmae">{d.billing_address}</div>,
      sortable: true,
      width: "260px",
      center: true,
    },
    {
      name: "GST Number",
      selector: "gstno",
      cell: (d) => <div className="loanid">{d.gstno}</div>,
      sortable: true,
      center: true,
    },
    {
      name: "Company Name",
      selector: "companyname",
      cell: (d) => <div className="colNmae2">{d.companyname}</div>,
      sortable: true,
      width: "370px",
      center: true,
    },

    {
      name: "edit",
      selector: "edit",
      cell: (d) => (
        <div>
          <FeatherIcon
            icon="edit"
            style={{ color: "#663595", cursor: "pointer" }}
            onClick={() => {
              handleViewOpen();
              setCurrentData(d);
              // setEditClient(d);
            }}
          />
        </div>
      ),
      sortable: true,
      width: "100px",
      center: true,
    },
    {
      name: "extra_col",
      selector: "empty_col",
      cell: (d) => <div></div>,
    },
  ];
  const dataTable = {
    columns: columns,
    data: datalist,
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const keysFormData = Object.keys(formData);
    if (keysFormData.length != 0) {
      axios
        .post(createClient, formData, config)
        .then((res) => {
          Swal.fire({
            title: "Client Created",
            icon: "success",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          Swal.fire({
            title: "Client Creation failed",
            icon: "error",
          });
        });
    }
    setFormData({});
  };

  const handleSubmitClientEdit = (e) => {
    e.preventDefault();

    editClient.clientid = currentData.clientid;
    
    handleViewClose();
    axios
      .put(update_client, editClient, dataToken)
      .then((res) =>
        Swal.fire({
          icon: "success",
          text: "Client Edited Successfully",
          iconColor: "#54B701",
          color: "#102464",
          confirmButtonColor: "#102464",
          title: "Success",
          confirmButtonText: "Done",
        })
      )
      .catch((err) =>
        Swal.fire({
          title: "Client Edit failed",
          icon: "error",
        })
      );
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };
  return (
    <div>
      <div className="d-flex justify-content-between heading_dash">
        <div className="invoceheader2022">
          <div>
            {location.pathname.split("/")[1] == "mainDetails" && (
              <span onClick={() => navigate(-1)} className="backarrowButton">
                &nbsp;
                <ArrowBackIcon />
              </span>
            )}
          </div>
          <div className="headimg_team"> Client </div>
        </div>
      </div>
      <>
        <div className="dash_container">
          <div className="table_data">
            <div className="menu_container">
              <Button
                className="createInvoicebutton"
                style={{ backgroundColor: "#663595" }}
                onClick={openModal}
              >
                Create Client
              </Button>
            </div>
            <div className="card_table">
              <DataTableExtensions
                {...dataTable}
                filterPlaceholder={`Search`}
                className="extension_option"
              >
                <DataTable
                  columns={columns}
                  data={datalist}
                  defaultSortFieldId={1}
                  pagination
                  paginationPerPage={7}
                  theme="solarized"
                />
              </DataTableExtensions>
            </div>
          </div>
        </div>
      </>

      <Dialog
        // PaperProps={{ sx: { position: "fixed", top: 150, center: 10, m: 0 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth2}
        open={showCI}
        scroll={scroll}
        onClose={closeModal}
      >
        <DialogTitle style={{ color: "#663595" }}>Client</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <label id="fullname">Full Name</label>
                <TextField
                  onChange={handleChangeForm}
                  name="fullname"
                  fullWidth
                  id="fullname"
                  type="text"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4}>
                <label id="emailid">Email Id</label>
                <TextField
                  onChange={handleChangeForm}
                  name="emailid"
                  fullWidth
                  id="emailid"
                  type="text"
                  variant="outlined"
                />
              </Grid>

              {/* <Grid item md={4}>
                <label id="podate">PO Date</label>
                <TextField
                fullWidth
                  id="podate"
                  type="date"
                  defaultValue="2017-05-24"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid> */}

              <Grid item md={4}>
                <label id="phoneno">Phone No.</label>
                <TextField
                  onChange={handleChangeForm}
                  name="phoneno"
                  fullWidth
                  id="phoneno"
                  type="text"
                  variant="outlined"
                />
              </Grid>

              <Grid item md={4}>
                <label id="designation">Designation</label>
                <TextField
                  onChange={handleChangeForm}
                  name="designation"
                  fullWidth
                  id="designation"
                  type="text"
                  variant="outlined"
                />
              </Grid>

              <Grid item md={4}>
                <label id="department">Department</label>
                <TextField
                  onChange={handleChangeForm}
                  name="department"
                  fullWidth
                  id="department"
                  type="text"
                  variant="outlined"
                />
              </Grid>

              <Grid item md={4}>
                <label id="gstno">GST NO.</label>
                <TextField
                  onChange={handleChangeForm}
                  name="gstno"
                  fullWidth
                  id="gstno"
                  type="text"
                  variant="outlined"
                />
              </Grid>

              <Grid item md={4}>
                <label id="companyname">Company Name</label>
                <TextField
                  onChange={handleChangeForm}
                  name="companyname"
                  fullWidth
                  id="companyname"
                  type="text"
                  variant="outlined"
                />
              </Grid>

              <Grid item md={4}>
                <label id="brandname">Brand Name</label>
                <TextField
                  onChange={handleChangeForm}
                  name="brand_name"
                  fullWidth
                  id="brandname"
                  type="text"
                  variant="outlined"
                />
              </Grid>

              <Grid item md={4}>
                <label id="website">Website</label>
                <TextField
                  onChange={handleChangeForm}
                  name="website"
                  fullWidth
                  id="website"
                  type="text"
                  variant="outlined"
                />
              </Grid>

              <Grid item md={4}>
                <label id="industry">Industry</label>
                <TextField
                  onChange={handleChangeForm}
                  name="industry"
                  fullWidth
                  id="industry"
                  type="text"
                  variant="outlined"
                />
              </Grid>

              <Grid item md={4}>
                <label id="organization">Organization</label>
                <TextField
                  onChange={handleChangeForm}
                  name="organization"
                  fullWidth
                  id="organization"
                  type="text"
                  variant="outlined"
                />
              </Grid>

              <Grid item md={4}>
                <label id="state">State</label>
                <TextField
                  onChange={handleChangeForm}
                  name="state"
                  fullWidth
                  id="state"
                  type="text"
                  variant="outlined"
                />
              </Grid>

              <Grid item md={4}>
                <label id="cin">CIN</label>
                <TextField
                  onChange={handleChangeForm}
                  name="cin"
                  fullWidth
                  id="cin"
                  type="text"
                  variant="outlined"
                />
              </Grid>

              <Grid item md={8}>
                <label id="aboutcompany">About Company</label>
                <TextField
                  onChange={handleChangeForm}
                  name="about_company"
                  fullWidth
                  minRows={2}
                  id="aboutcompany"
                  type="text"
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6}>
                <label id="billingaddress">Billing Address</label>
                <Textarea
                  onChange={handleChangeForm}
                  name="billing_address"
                  minRows={2}
                  variant="outlined"
                  style={{ border: " 1px solid #c4c4c4", borderRadius: "4px" }}
                />
              </Grid>

              <Grid item md={6}>
                <label id="corporateaddress">Corporate Address</label>
                <Textarea
                  onChange={handleChangeForm}
                  name="corporate_address"
                  id="corporateaddress"
                  minRows={2}
                  variant="outlined"
                  style={{ border: " 1px solid #c4c4c4", borderRadius: "4px" }}
                />
              </Grid>
            </Grid>
            <Submitbutton
              variant="outlined"
              className="formsubmitbutton"
              onClick={closeModal}
              startIcon={<AddIcon />}
              style={{ postion: "fixed", bottom: "-20px", left: "1045px" }}
              type="submit"
            >
              Client
            </Submitbutton>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      {/* {viewOpen && (
        <Dialog
          open={viewOpen}
          onClose={handleViewClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        
          maxWidth="md"
        >
          <DialogTitle style={{ color: "#663595" }}>Edit Client </DialogTitle>
          <form onSubmit={handleSubmitClientEdit}>
            <DialogContent>
              <Grid container spacing={"2"}>
                <Grid item xs={6}>
                  <InputLabel>Client Name</InputLabel>
                  <TextField
                    fullWidth={true}
                    defaultValue={currentData.fullname}
                    name="fullname"
                    onChange={handleClientEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>Phone Number</InputLabel>
                  <TextField
                    fullWidth={true}
                    defaultValue={currentData.phoneno}
                    name="phoneno"
                    onChange={handleClientEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>billing Address</InputLabel>
                  <TextField
                    fullWidth={true}
                    defaultValue={currentData.billing_address}
                    name="billing_address"
                    onChange={handleClientEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>GST Number</InputLabel>
                  <TextField
                    fullWidth={true}
                    defaultValue={currentData.gstno}
                    name="gstno"
                    onChange={handleClientEdit}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>Company Name</InputLabel>
                  <TextField
                    fullWidth={true}
                    defaultValue={currentData.companyname}
                    name="companyname"
                    onChange={handleClientEdit}
                  />
                </Grid>
               
              </Grid>
            </DialogContent>
            <DialogActions>
              <Submitbutton
                variant="contained"
                disabled={
                  JSON.stringify(currentData) == JSON.stringify(editClient)
                }
                style={{
                  backgroundColor:
                    JSON.stringify(currentData) == JSON.stringify(editClient)
                      ? ""
                      : "#663595",
                }}
                type="submit"
              >
                Submit
              </Submitbutton>
            </DialogActions>
          </form>
        </Dialog>
      )} */}

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth2}
        scroll={scroll}
        open={viewOpen}
        onClose={handleViewClose}
      >
        <DialogTitle style={{ color: "#663595" }}>Client</DialogTitle>
        <form onSubmit={handleSubmitClientEdit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <label id="fullname">Full Name</label>
                <TextField
                  onChange={handleClientEdit}
                  name="fullname"
                  fullWidth
                  id="fullname"
                  type="text"
                  variant="outlined"
                  defaultValue={currentData.fullname}
                />
              </Grid>

              <Grid item md={4}>
                <label id="emailid">Email Id</label>
                <TextField
                  onChange={handleClientEdit}
                  name="emailid"
                  fullWidth
                  id="emailid"
                  type="text"
                  variant="outlined"
                  defaultValue={currentData.emailid}
                />
              </Grid>

              <Grid item md={4}>
                <label id="phoneno">Phone No.</label>
                <TextField
                  onChange={handleClientEdit}
                  name="phoneno"
                  fullWidth
                  id="phoneno"
                  type="text"
                  variant="outlined"
                  defaultValue={currentData.phoneno}
                />
              </Grid>

              <Grid item md={4}>
                <label id="designation">Designation</label>
                <TextField
                  onChange={handleClientEdit}
                  name="designation"
                  fullWidth
                  id="designation"
                  type="text"
                  variant="outlined"
                  defaultValue={currentData.designation}
                />
              </Grid>

              <Grid item md={4}>
                <label id="department">Department</label>
                <TextField
                  onChange={handleClientEdit}
                  name="department"
                  fullWidth
                  id="department"
                  type="text"
                  variant="outlined"
                  defaultValue={currentData.department}
                />
              </Grid>

              <Grid item md={4}>
                <label id="gstno">GST NO.</label>
                <TextField
                  onChange={handleClientEdit}
                  name="gstno"
                  fullWidth
                  id="gstno"
                  type="text"
                  variant="outlined"
                  defaultValue={currentData.gstno}
                />
              </Grid>

              <Grid item md={4}>
                <label id="companyname">Company Name</label>
                <TextField
                  onChange={handleClientEdit}
                  name="companyname"
                  fullWidth
                  id="companyname"
                  type="text"
                  variant="outlined"
                  defaultValue={currentData.companyname}
                />
              </Grid>

              <Grid item md={4}>
                <label id="brandname">Brand Name</label>
                <TextField
                  onChange={handleClientEdit}
                  name="brand_name"
                  fullWidth
                  id="brandname"
                  type="text"
                  variant="outlined"
                  defaultValue={currentData.brand_name}
                />
              </Grid>

              <Grid item md={4}>
                <label id="state">State</label>
                <TextField
                  onChange={handleClientEdit}
                  name="state"
                  fullWidth
                  id="state"
                  type="text"
                  variant="outlined"
                  defaultValue={currentData.state}
                />
              </Grid>

              <Grid item md={4}>
                <label id="billingaddress">Billing Address</label>
                <Textarea
                  onChange={handleClientEdit}
                  name="billing_address"
                  minRows={2}
                  variant="outlined"
                  style={{ border: " 1px solid #c4c4c4", borderRadius: "4px" }}
                  defaultValue={currentData.billing_address}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Submitbutton
              variant="contained"
              style={{ width: "14%" }}
              type="submit"
              size="large"
            >
              Submit
            </Submitbutton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default ClientTable;
