import axios from "axios";
import React, { useState } from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { dataToken } from "../../Config/token";
import { createPaymentreq } from "../../utils";
function AddPayout() {
  const [data, setData] = useState({});
  const invoiceId = localStorage.getItem("invoiceId");
  const handlesubmit = (event) => {
    event.preventDefault();
    data["invoice_id"] = invoiceId;
    if (data.type) {
      axios
        .post(createPaymentreq, data, dataToken)
        .then((res) => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `success`,
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: `${err.response.data.message}`,
          });
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Oops!",
        text: `Select the type of Payment`,
      });
    }
  };
  return (
    <div>
      <span className="add_heading"> Add Payout Details</span>
      <Form onSubmit={handlesubmit}>
        <Row className="mt-5">
          <Col sx={12} md={6}>
            <Form.Group className="mb-4 drop_flex" controlId="ControlInput">
              <Form.Label>Payout Type</Form.Label>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  data.type = e.target.value;
                  setData({ ...data });
                }}
                className={"drop_css"}
                required
              >
                <option selected disabled>
                  Select Payout Type
                </option>
                <option value={"auto_payment"}>Auto Payment</option>
                <option value={"manual_payment"}>Manual Payment</option>
                <option value={"other_expenses"}>Other Expenses</option>
                {/* <option value={"auto_payment"}>Auto Payment</option> */}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>SOW ID</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  data.sow_id = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sx={12} md={4}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Payment Start Date</Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => {
                  data.start_date = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={4}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Payment End Date</Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => {
                  data.end_date = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={4}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Payment Processing Date</Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => {
                  data.payment_date = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>No Of Leads</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  data.leads = e.target.value;
                  setData({ ...data });
                }}
                required
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Cost per Item</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  data.cpl = e.target.value;
                  setData({ ...data });
                }}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Billable Leads</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  data.billable_leads = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Billable Amount</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  data.billing_amt = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Total Cost</Form.Label>
              <Form.Control
                type="text"
                value={data.cpl * data.leads}
                disabled
              />
            </Form.Group>
          </Col>
          <Col sx={12} md={6}>
            <Form.Group className="mb-4" controlId="ControlInput">
              <Form.Label>Remarks</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  data.remark = e.target.value;
                  setData({ ...data });
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end">
          <Button
            className={"button_submit btn_pos"}
            type="submit"
            style={{ marginRight: "50px" }}
          >
            ADD
          </Button>
        </Row>
      </Form>
    </div>
  );
}

export default AddPayout;
