import React, { useState } from "react";

import { Col, Row } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import CircularProgress from "@mui/material/CircularProgress";
import { dataToken, token } from "../../Config/token";
import {
  payout_report,
  upload_lead_report,
  upload_payout_report,
} from "../../utils";
import Modal from "react-bootstrap/Modal";
import RepDetails from "../pages/RepDetails";
import axios from "axios";
import Swal from "sweetalert2";
import "../pages/Payout.css";
function Dump({ d }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [show1, setShow1] = useState(false);

  const imageUpload1 = (e, id) => {
    setLoading(true);
    const axiosData = new FormData();
    axiosData.append("file", e.target.files[0]);
    axiosData.append("req_id", id);
    axios
      .post(upload_payout_report, axiosData, dataToken)
      .then((res) => {
        setData(res.data.rep);
        setShow1(true);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  const imageUpload = (e, id, type) => {
    

    const axiosData = new FormData();
    axiosData.append("file", e.target.files[0]);
    axiosData.append("id", id);
    axiosData.append("type", type);
    
    axios
      .post(upload_lead_report, axiosData, dataToken)
      .then((res) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `success`,
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <Row className="mt-5">
        <h4 className="mb-4 leadgenerator">Dump Update</h4>
      </Row>
      <Row className="mt-2">
        <Col sx={4} md={4}>
          <div className="d-flex flex-column align-items-center">
            <h4 className="mb-4 leadgenerator1">Lead Report</h4>
            {d.lead_report !== "" ? (
              <div className="d-flex ">
                <a href={d.lead_report}>
                  <button className="btn-css-download">
                    <FeatherIcon icon="download-cloud" size={24} />
                  </button>
                </a>
              </div>
            ) : (
              <div className="d-flex">
                <label className="btn-css">
                  <FeatherIcon icon="upload-cloud" size={24} />
                  <input
                    type="file"
                    onChange={(e) => {
                      imageUpload(e, d.req_id, "lead_report");
                    }}
                  />
                </label>
              </div>
            )}
          </div>
        </Col>
        <Col sx={4} md={4}>
          <div className="d-flex flex-column align-items-center">
            <h4 className="mb-4 leadgenerator1">Manual Report</h4>
            {d.manual_report !== "" ? (
              <div className="d-flex ">
                <a href={d.manual_report}>
                  <button className="btn-css-download">
                    <FeatherIcon icon="download-cloud" size={24} />
                  </button>
                </a>
              </div>
            ) : (
              <>
                {d.type == "other_expenses" ? (
                  <div className="d-flex ">
                    <label className="btn-css">
                      <FeatherIcon icon="upload-cloud" size={24} />
                      <input
                        type="file"
                        onChange={(e) => {
                          imageUpload(e, d.req_id, "manual_report");
                        }}
                      />
                    </label>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </Col>
        <Col sx={4} md={4}>
          <div className="d-flex flex-column align-items-center">
            <h4 className="mb-4 leadgenerator1">Payout Data</h4>
            {d.count > 0 ? (
              <div className="d-flex ">
                <a href={`${payout_report}?token=${token}&req_id=${d.req_id}`}>
                  <button className="btn-css-download">
                    <FeatherIcon icon="download-cloud" size={24} />
                  </button>
                </a>
              </div>
            ) : (
              <>
                <div className="d-flex ">
                  <label className="btn-css">
                    {loading ? (
                      <CircularProgress size={24} className="text-light" />
                    ) : (
                      <FeatherIcon icon="upload-cloud" size={24} />
                    )}
                    <input
                      type="file"
                      onChange={(e) => {
                        imageUpload1(e, d.req_id);
                      }}
                    />
                  </label>
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
      <Modal centered show={show1} onHide={() => setShow1(false)}>
        <div className="add_page1">
          <RepDetails data={data} />
        </div>
      </Modal>
    </div>
  );
}

export default Dump;
